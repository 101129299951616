import { clone, find, get } from "lodash";
import {
  GET_ARTICLE_COMMENTS_BY_ID_PROCESS_SUCCESS,
  SET_ALL_ARTICLES_COMMENTS_BY_ID_PAGE_START,
  RESET_ALL_COMMENTS_BY_ID_START,
  PUT_ARTICLE_COMMENTS_BY_ID_PROCESS_SUCCESS,
} from "../actions/ArticlesCommentsActions";

const ArticleCommentsReducer = (
  state = { items: [], page: 1, hasNext: false },
  action
) => {
  const { type, payload } = action;
  let newItems, articleId;

  switch (type) {
    case PUT_ARTICLE_COMMENTS_BY_ID_PROCESS_SUCCESS:
      articleId = payload.articleId;
      newItems = [payload.item].concat(state[articleId].items);
      return {
        ...state,
        [articleId]: {
          ...state[articleId],
          items: newItems,
        },
      };
    case GET_ARTICLE_COMMENTS_BY_ID_PROCESS_SUCCESS:
      articleId = payload.articleId;
      const items = get(state, [articleId, "items"], []);
      newItems = clone(items);
      const itemsToAdd = payload.items;
      itemsToAdd.forEach((it) => {
        const isPresent = find(newItems, { id: it.id });
        if (!isPresent) {
          newItems.push(it);
        }
      });

      return {
        ...state,
        [articleId]: {
          items: newItems,
          hasNext: payload.hasNext,
          page: payload.page || 1,
        },
      };
    case SET_ALL_ARTICLES_COMMENTS_BY_ID_PAGE_START:
      articleId = payload.articleId;
      return {
        ...state,
        [articleId]: {
          ...state[articleId],
          page: state[articleId].page + 1,
        },
      };
    case RESET_ALL_COMMENTS_BY_ID_START:
      const newState = clone(state);
      delete newState[payload.articleId];
      return {
        ...newState,
      };
    default:
      return {
        ...state,
      };
  }
};

export default ArticleCommentsReducer;
