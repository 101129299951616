import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  PUT_ARTICLE_COMMENTS_BY_ID_PROCESS,
  PUT_ARTICLE_COMMENTS_BY_ID_START,
} from "../actions/ArticlesCommentsActions";
import {
  GREEN_COLOR,
  GREEN_COLOR_LIGHTER,
  MAIN_DARK_COLOR,
  MAIN_LIGHT_COLOR,
} from "../config/colors";
import { i18n } from "../config/i18n";
import { useSelector } from "react-redux";
import ErrorAlertComponent from "./ErrorAlertComponent";

const AddCommentForm = ({ articleId }) => {
  const isAuthenticated = useSelector(({ LoginReducer }) => LoginReducer.token);
  const [comment, setComment] = useState();
  const [wasSent, setWasSent] = useState(false);
  const dispatch = useDispatch();

  if (!isAuthenticated) {
    return null;
  }

  return (
    <div>
      <h4>{i18n.ADD_COMMENT}</h4>
      <ErrorAlertComponent processName={PUT_ARTICLE_COMMENTS_BY_ID_PROCESS} />
      {wasSent && (
        <span style={{ color: GREEN_COLOR }}>{i18n.COMMENT_WAS_SENT}</span>
      )}
      {!wasSent && (
        <form
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            height: "150px",
          }}
        >
          <textarea
            style={{ flex: 1, marginLeft: "10xp", marginRight: "10px" }}
            value={comment}
            onChange={(e) => setComment(e.currentTarget.value)}
          ></textarea>

          <button
            style={{
              maxWidth: "150px",
              padding: "10px",
              marginTop: "10px",
              backgroundColor: MAIN_DARK_COLOR,
              color: MAIN_LIGHT_COLOR,
              fontFamily: "Lato",
              fontSize: "14px",
              border: "none",
              borderRadius: "10px",
              alignSelf: "flex-end",
            }}
            onClick={(e) => {
              e.preventDefault();
              dispatch({
                type: PUT_ARTICLE_COMMENTS_BY_ID_START,
                payload: { articleId, comment },
              });
              setComment("");
              setWasSent(true);
            }}
          >
            {i18n.ADD_COMMENT_BTN}
          </button>
        </form>
      )}
    </div>
  );
};

export default AddCommentForm;
