import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { i18n } from "../config/i18n";
import logo from "../img/logo.png";
import AuthWelcome from "./AuthWelcome";
import MobileMenu from "./MobileMenu";

const Header = () => {
  const { MAIN_DARK_COLOR, MAIN_LIGHT_COLOR } = useSelector(
    ({ ConfigReducer }) => ConfigReducer.colors
  );

  const token = useSelector(({ LoginReducer }) => LoginReducer.token);

  return (
    <div>
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "flex-end",
          paddingRight: 20,
          paddingTop: 20,
        }}
      >
        <AuthWelcome />
      </div>
      <nav
        style={{
          display: "flex",
          flexDirection: "row",
          flex: 0,
          backgroundColor: MAIN_LIGHT_COLOR,
          alignItems: "flex-start",
          padding: 20,
        }}
      >
        <Link to="/">
          <img src={logo} style={{ height: 75 }} />
        </Link>
        <MobileMenu />
        <ul id="desktopMenu" className="hide-on-mobile-tablet">
          <li style={{ flex: 0.2, textAlign: "center" }}>
            <Link
              to="/"
              style={{
                color: MAIN_DARK_COLOR,
                fontFamily: "Lato",
                fontSize: 24,
                textDecoration: "none",
                textAlign: "center",
              }}
            >
              {i18n.HOME}
            </Link>
          </li>

          <li style={{ flex: 0.2, textAlign: "center" }}>
            <Link
              to="/companies"
              style={{
                color: MAIN_DARK_COLOR,
                fontFamily: "Lato",
                fontSize: 24,
                textDecoration: "none",
                textAlign: "center",
              }}
            >
              {i18n.COMPANIES}
            </Link>
          </li>
          <li style={{ flex: 0.2, textAlign: "center" }}>
            <Link
              to="/espi"
              style={{
                color: MAIN_DARK_COLOR,
                fontFamily: "Lato",
                fontSize: 24,
                textDecoration: "none",
              }}
            >
              {i18n.ESPI}
            </Link>
          </li>
          <li style={{ flex: 0.2, textAlign: "center" }}>
            <Link
              to="/about"
              style={{
                color: MAIN_DARK_COLOR,
                fontFamily: "Lato",
                fontSize: 24,
                textDecoration: "none",
              }}
            >
              {i18n.ABOUT}
            </Link>
          </li>
          <li style={{ flex: 0.2, textAlign: "center" }}>
            <Link
              to="/terms"
              style={{
                color: MAIN_DARK_COLOR,
                fontFamily: "Lato",
                fontSize: 24,
                textDecoration: "none",
              }}
            >
              {i18n.TERMS}
            </Link>
          </li>
          {!token && (
            <li style={{ flex: 0.2, textAlign: "center" }}>
              <Link
                to="/login"
                style={{
                  color: MAIN_DARK_COLOR,
                  fontFamily: "Lato",
                  fontSize: 24,
                  textDecoration: "none",
                  textAlign: "center",
                }}
              >
                Login
              </Link>
            </li>
          )}
        </ul>
      </nav>
    </div>
  );
};

export default Header;
