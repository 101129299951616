import React, { useEffect } from "react";
import BasePage from "../components/BasePage";
import { useDispatch, useSelector } from "react-redux";
import {
  GET_ALL_ARTICLES_START,
  SET_ALL_ARTICLES_PAGE_START,
} from "../actions/ArticlesActions";
import ArticleTeaser from "../components/ArticleTeaser";
import { i18n } from "../config/i18n";
import { MAIN_DARK_COLOR } from "../config/colors";
import { POST_REFRESH_TOKEN_START } from "../actions/LoginActions";

const HomeRoute = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: GET_ALL_ARTICLES_START,
    });
    dispatch({
      type: POST_REFRESH_TOKEN_START,
    });
  }, [dispatch]);

  const articles = useSelector(({ ArticlesReducer }) => ArticlesReducer.items);
  const hasNext = useSelector(({ ArticlesReducer }) => ArticlesReducer.hasNext);

  return (
    <BasePage>
      {articles.map((article) => (
        <ArticleTeaser article={article} key={`key_${article.id}`} />
      ))}
      {hasNext && (
        <button
          style={{
            marginTop: "20px",
            marginBottom: "20px",
            border: "none",
            background: "none",
            color: MAIN_DARK_COLOR,
            fontWeight: "bold",
            fontSize: "1.2rem",
          }}
          onClick={() =>
            dispatch({
              type: SET_ALL_ARTICLES_PAGE_START,
            })
          }
        >
          {i18n.MORE_ARTICLES}
        </button>
      )}
    </BasePage>
  );
};

export default HomeRoute;
