export const GET_REPORT_DOWNLOADS_PROCESS = "GET_REPORT_DOWNLOADS_PROCESS";
export const GET_REPORT_DOWNLOADS_PROCESS_START = "GET_REPORT_DOWNLOADS_PROCESS_START";
export const GET_REPORT_DOWNLOADS_PROCESS_SUCCESS = "GET_REPORT_DOWNLOADS_PROCESS_SUCCESS";
export const GET_REPORT_DOWNLOADS_PROCESS_ERROR = "GET_REPORT_DOWNLOADS_PROCESS_ERROR";
export const GET_REPORT_DOWNLOADS_PROCESS_RESET = "GET_REPORT_DOWNLOADS_PROCESS_RESET";

export const GET_REPORT_DOWNLOAD_ITEM_PROCESS = "GET_REPORT_DOWNLOAD_ITEM_PROCESS";
export const GET_REPORT_DOWNLOAD_ITEM_PROCESS_START = "GET_REPORT_DOWNLOAD_ITEM_PROCESS_START";
export const GET_REPORT_DOWNLOAD_ITEM_PROCESS_SUCCESS = "GET_REPORT_DOWNLOAD_ITEM_PROCESS_SUCCESS";
export const GET_REPORT_DOWNLOAD_ITEM_PROCESS_ERROR = "GET_REPORT_DOWNLOAD_ITEM_PROCESS_ERROR";
export const GET_REPORT_DOWNLOAD_ITEM_PROCESS_RESET = "GET_REPORT_DOWNLOAD_ITEM_PROCESS_RESET";

export const GET_REPORT_DOWNLOAD_ITEM_PROCESS_PROGRESS = "GET_REPORT_DOWNLOAD_ITEM_PROCESS_PROGRESS";