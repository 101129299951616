import { get } from 'lodash';
import {
  GET_REPORT_DOWNLOADS_PROCESS_RESET,
  GET_REPORT_DOWNLOAD_ITEM_PROCESS_PROGRESS,
  GET_REPORT_DOWNLOADS_PROCESS_SUCCESS,
} from '../actions/ReportDownloadsActions';

const ReportDownloadsReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case GET_REPORT_DOWNLOAD_ITEM_PROCESS_PROGRESS:
      return {
        ...state,
        progress: payload,
      };
    case GET_REPORT_DOWNLOADS_PROCESS_RESET:
      return {};
    case GET_REPORT_DOWNLOADS_PROCESS_SUCCESS:
      const { downloads } = payload;
      if (!downloads) {
        return {
          ...state,
        };
      }
      let objectToAdd = {};
      downloads.forEach((it) => {
        if (!objectToAdd[it.reportId]) {
          objectToAdd[it.reportId] = [it];
        } else {
          objectToAdd[it.reportId].push(it);
        }
      });
      return {
        ...state,
        ...objectToAdd,
      };
    default:
      return {
        ...state,
      };
  }
};

export default ReportDownloadsReducer;
