import React from "react";
import { Link } from "react-router-dom";
import { MAIN_DARK_COLOR, MAIN_DARK_COLOR_LIGHTER } from "../config/colors";
import { i18n } from "../config/i18n";
import appstore from "../img/appstore.png";
import googleplay from "../img/googleplay.png";

const Footer = () => {
  return (
    <div
      style={{
        padding: 20,

        maxWidth: 1280,
        display: "flex",
        alignSelf: "center",
        flexDirection: "column",
        marginLeft: "10px",
        marginRight: "10px",
      }}
    >
      <hr
        style={{
          borderColor: MAIN_DARK_COLOR_LIGHTER,
        }}
      />
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "row",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <Link to="/" className="menuLink">
            {i18n.HOME}
          </Link>
          <Link to="/about" className="menuLink">
            {i18n.ABOUT}
          </Link>
          <Link to="/terms" className="menuLink">
            {i18n.TERMS}
          </Link>
        </div>
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "row",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <a
            href="https://stockup.cloud"
            target="_blank"
            rel="noopener noreferrer"
          >
            Stara wersja
          </a>
          <a
            href="https://apps.apple.com/us/app/stockup/id1517486644"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={appstore} alt="appstore" style={{ maxWidth: "100%" }} />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=cloud.stockup.mobile&hl=pl_PL"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={googleplay}
              alt="googleplay"
              style={{ maxWidth: "100%" }}
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
