import { filter } from "lodash";
import React, { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { GET_TAB_COMPANIES_START } from "../actions/CompanyActions";
import BasePage from "../components/BasePage";
import CompanyLogo from "../components/CompanyLogo";
import { MAIN_DARK_COLOR } from "../config/colors";
import { inputLabelStyle, inputStyle } from "../config/CommonStyles";
import { i18n } from "../config/i18n";

const CompaniesRoute = () => {
  const dispatch = useDispatch();

  const [commpanyNameFilter, setCompanyNameFilter] = useState();

  useEffect(() => {
    console.log("get companies");
    dispatch({
      type: GET_TAB_COMPANIES_START,
    });
  }, []);

  const companies = useSelector(
    ({ CompaniesTabReducer }) => CompaniesTabReducer.items
  );

  const filteredCompanies = filter(companies, (item) => {
    if (!commpanyNameFilter || commpanyNameFilter === "") {
      return true;
    }
    return (
      item.name.name.toLowerCase().indexOf(commpanyNameFilter) !== -1 ||
      item.symbol.abbreviation.toLowerCase().indexOf(commpanyNameFilter) !==
        -1 ||
      commpanyNameFilter === ""
    );
  });

  return (
    <BasePage>
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          marginLeft: "40px",
        }}
      >
        <label style={inputLabelStyle}>{i18n.FIND_COMPANY} </label>
        <input
          style={inputStyle}
          type="text"
          value={commpanyNameFilter}
          onChange={(e) => setCompanyNameFilter(e.currentTarget.value)}
        />
      </div>
      <ul>
        {filteredCompanies &&
          filteredCompanies.map((company) => {
            const companyDetailsLink = `/companies/${company.symbol.abbreviation}`;
            return (
              <li
                key={`company_list_${company.symbol.abbreviation}`}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: 10,
                  paddingBottom: 10,
                  borderBottom: "1px solid #ccc",
                  textDecoration: "none",
                }}
              >
                <div>
                  <Link
                    to={companyDetailsLink}
                    style={{ textDecoration: "none" }}
                  >
                    <h2
                      style={{
                        fontWeight: "bold",
                        fontFamily: "Lato",
                        color: MAIN_DARK_COLOR,
                      }}
                    >
                      {company.symbol.abbreviation}
                    </h2>
                  </Link>
                  <Link
                    to={companyDetailsLink}
                    style={{ textDecoration: "none" }}
                  >
                    <h3
                      style={{
                        fontWeight: "thin",
                        fontFamily: "Lato",
                        color: MAIN_DARK_COLOR,
                      }}
                    >
                      {company.name.name}
                    </h3>
                  </Link>
                </div>
                <a href={companyDetailsLink} style={{ textDecoration: "none" }}>
                  <CompanyLogo symbol={company.symbol.abbreviation} />
                </a>
              </li>
            );
          })}
      </ul>
    </BasePage>
  );
};

export default CompaniesRoute;
