import { all } from "@redux-saga/core/effects";
import ConfigSagas from "./ConfigSagas";
import ArticlesSagas from "./ArticlesSagas";
import ReportSagas from "./ReportSagas";
import ReportDownloadsSagas from "./ReportDownloadsSagas";
import LoginSagas from "./LoginSagas";
import ArticlesCommentsSagas from "./ArticlesCommentsSagas";
import CompanySagas from "./CompanySagas";
import ChartSagas from "./ChartSagas";
import AnalyzerAlertsSagas from "./AnalyzerAlertsSagas";
import ChatSagas from "./ChatSagas";

export default function* saga() {
  yield all([
    ConfigSagas(),
    ArticlesSagas(),
    ReportSagas(),
    ReportDownloadsSagas(),
    LoginSagas(),
    ArticlesCommentsSagas(),
    CompanySagas(),
    ChartSagas(),
    AnalyzerAlertsSagas(),
    ChatSagas(),
  ]);
}
