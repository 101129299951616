import { call, put, select, takeEvery } from "@redux-saga/core/effects";
import { filter, get } from "lodash";
import {
  GET_ARTICLE_COMMENTS_BY_ID_PROCESS,
  GET_ARTICLE_COMMENTS_BY_ID_PROCESS_SUCCESS,
  GET_ARTICLE_COMMENTS_BY_ID_START,
  PUT_ARTICLE_COMMENTS_BY_ID_PROCESS,
  PUT_ARTICLE_COMMENTS_BY_ID_PROCESS_SUCCESS,
  PUT_ARTICLE_COMMENTS_BY_ID_START,
  SET_ALL_ARTICLES_COMMENTS_BY_ID_PAGE_START,
} from "../actions/ArticlesCommentsActions";
import {
  getArticleCommentsById,
  putArticleComment,
} from "../services/ArticleCommentsService";
import genericSagaHandler from "./CommonSaga";

function* doGetArticleCommentsByIdSaga(action) {
  yield* genericSagaHandler(GET_ARTICLE_COMMENTS_BY_ID_PROCESS, function* () {
    const { articleId } = action.payload;
    const page = yield select(({ ArticleCommentsReducer }) => {
      return get(ArticleCommentsReducer, [articleId, "page"], 1);
    });

    const { data } = yield getArticleCommentsById({ articleId, page });
    const hasNext =
      filter(get(data, ["links"]), (item) => item.rel === "nextPage").length >
      0;
    yield put({
      type: GET_ARTICLE_COMMENTS_BY_ID_PROCESS_SUCCESS,
      payload: {
        articleId,
        page,
        items: data.items,
        hasNext,
      },
    });
  });
}

function* doPutArticleCommentsByIdSaga(action) {
  yield* genericSagaHandler(PUT_ARTICLE_COMMENTS_BY_ID_PROCESS, function* () {
    const { articleId, comment } = action.payload;
    const login = yield select(({ LoginReducer }) => LoginReducer.email);
    yield putArticleComment({ articleId, content: comment, login });
    const { data } = yield getArticleCommentsById({
      articleId,
      page: 1,
      pageSize: 1,
    });

    // yield call(doGetArticleCommentsByIdSaga, { payload: { articleId } });
    yield put({
      type: PUT_ARTICLE_COMMENTS_BY_ID_PROCESS_SUCCESS,
      payload: { item: data.items[0], articleId },
    });
  });
}

export default function* ArticlesCommentsSagas() {
  yield takeEvery(
    GET_ARTICLE_COMMENTS_BY_ID_START,
    doGetArticleCommentsByIdSaga
  );

  yield takeEvery(
    PUT_ARTICLE_COMMENTS_BY_ID_START,
    doPutArticleCommentsByIdSaga
  );

  yield takeEvery(
    SET_ALL_ARTICLES_COMMENTS_BY_ID_PAGE_START,
    doGetArticleCommentsByIdSaga
  );
}
