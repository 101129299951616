import { all, call, put, select, take, takeEvery } from "redux-saga/effects";
import {
  GET_REPORTS_START,
  REPORTS_PAGES_GET_PROCESS_START,
  REPORTS_PAGES_GET_PROCESS_SUCCESS,
  REPORTS_PAGES_RESET,
  GET_REPORT_BY_ID_PROCESS_SUCCESS,
  GET_COMPANY_REPORTS_PROCESS,
  GET_COMPANY_REPORTS_PROCESS_SUCCESS,
  GET_COMPANY_REPORTS_START,
  SET_COMPANY_REPORTS_PAGE_START,
  GET_REPORTS_PROCESS,
  GET_REPORTS_PROCESS_SUCCESS,
  SET_REPORTS_PAGE_START,
  WEBSOCKET_RECEIVED_REPORT_SUCCESS,
  WEBSOCKET_RECEIVED_REPORT_START,
  WEBSOCKET_OPENED,
  START_REPORTS_WEBSOCKET,
  GET_REPORT_BY_ID_RESET,
  GET_REPORT_BY_ID_PROCESS,
  GET_REPORT_BY_ID_START,
  REPORTS_ON_REFRESH,
  REPORTS_RESET,
  RESET_COMPANY_REPORTS_PAGE_START,
  RESET_REPORTS_PAGE_START,
  RESET_REPORTS_SEARCH_DATA,
  STOP_REPORTS_WEBSOCKET,
  SET_COMPANY_REPORTS_PREVIOUS_PAGE_START,
} from "../actions/ReportsActions";
import { MARKET } from "../config/constants";
import {
  getCompanyReports,
  getReportById,
  getReports,
} from "../services/ReportsService";
import { filter, get } from "lodash";
// import genericTemporarySagaHandler from "./CommonTemporarySaga";
import genericSagaHandler from "./CommonSaga";
import { eventChannel } from "@redux-saga/core";
import HttpService from "../services/HttpService";
import MobileConfig from "../config/Config";

import { analyzeReports } from "../services/AnalyzeReportsService";
import dayjs from "dayjs";
import { LOGOUT_PROCESS_START, LOGOUT_START } from "../actions/LoginActions";

let channel;
let socketInterval;

function* doGetCompanyReports({ payload }) {
  yield genericSagaHandler(GET_COMPANY_REPORTS_PROCESS, function* () {
    const company = get(payload, "company");
    const market = MARKET;

    const page = yield select(({ ReportsReducer }) => ReportsReducer.page);
    console.log(page);
    const response = yield getCompanyReports({
      company,
      market,
      page,
    });

    const hasNextPage =
      filter(
        get(response, ["data", "links"]),
        (item) => item.rel === "nextPage"
      ).length > 0;

    const items = get(response, ["data", "items"]);
    yield put({
      type: GET_COMPANY_REPORTS_PROCESS_SUCCESS,
      payload: {
        items,
        page,
        hasNextPage,
        symbol: company,
      },
    });
  });
}

function* doGetReports() {
  yield genericSagaHandler(GET_REPORTS_PROCESS, function* () {
    const page = yield select(
      ({ AllReportsReducer }) => AllReportsReducer.page
    );
    const { keyword, startDate, endDate } = yield select(
      ({ AllReportsReducer }) => AllReportsReducer
    );

    const fromDateTime = dayjs(startDate).format("YYYY-MM-DDT00:00:01");
    const toDateTime = dayjs(endDate).format("YYYY-MM-DDT23:59:59");

    const response = yield getReports({
      market: MARKET,
      page,
      Content: keyword,
      FromDateTime: fromDateTime,
      ToDateTime: toDateTime,
    });

    const hasNextPage =
      filter(
        get(response, ["data", "links"]),
        (item) => item.rel === "nextPage"
      ).length > 0;

    const items = get(response, ["data", "items"]);
    yield put({
      type: GET_REPORTS_PROCESS_SUCCESS,
      payload: {
        items,
        page,
        hasNextPage,
      },
    });
  });
}

// function* doGetPagesReports({ payload }) {
//   yield genericTemporarySagaHandler(function* () {
//     const company = get(payload, "company");
//     const page = get(payload, "currentPage", 1);
//     if (page === 1) {
//       yield put({
//         type: REPORTS_PAGES_RESET,
//       });
//     }
//     const pageSize = get(payload, "pageSize");
//     const market = MARKET;
//     console.log("get reports");
//     const { data } = yield getCompanyReports({
//       company,
//       market,
//       page,
//       pageSize,
//     });
//     yield put({
//       type: REPORTS_PAGES_GET_PROCESS_SUCCESS,
//       payload: data,
//     });
//   });
// }

function* doGetReportById({ payload }) {
  yield genericSagaHandler(GET_REPORT_BY_ID_PROCESS, function* () {
    const { reportId } = payload;
    yield put({
      type: GET_REPORT_BY_ID_RESET,
    });
    const { data } = yield getReportById(reportId);

    yield put({
      type: GET_REPORT_BY_ID_PROCESS_SUCCESS,
      payload: data,
    });
  });
}

function initReportsWebsocket() {
  return eventChannel((emitter) => {
    HttpService.post(
      `${MobileConfig.BASE_URL}reportHub/negotiate?negotiateVersion=1`
    ).then((resp) => {
      console.log(resp);
      const ws = new WebSocket(
        `${MobileConfig.BASE_WSS_URL}/reportHub?id=${resp.data.connectionToken}`
      );

      ws.onopen = () => {
        // connection opened
        // ws.send('something'); // send a message
        console.log("WebSockets", "openened");
        ws.send('{"protocol":"json","version":1}');
        return emitter({ type: WEBSOCKET_OPENED });
      };

      ws.onerror = (e) => {
        // an error occurred
        console.log("WebSockets e", e);
      };

      socketInterval = setInterval(() => {
        console.log("Websockets send ping");
        ws.send("{type: 6}");
      }, 15000);

      ws.onmessage = (e) => {
        // just don't ask about the slice, it is the messed up response
        const message = JSON.parse(e.data.slice(0, -1));

        if (message.target === "ReceiveReport") {
          console.log("WebSockets", e, e.data, typeof e.data);
          return emitter({ type: WEBSOCKET_RECEIVED_REPORT_START, message });
        }
      };
    });

    return () => {
      console.log("unsub");
    };
  });
}

function* doStartReportsWebsocket() {
  if (channel && socketInterval) {
    clearInterval(socketInterval);
    channel.close();
  }

  channel = yield call(initReportsWebsocket);
  while (true) {
    const action = yield take(channel);
    console.log("WebSockets", action);
    if (action.type === WEBSOCKET_RECEIVED_REPORT_START) {
      yield put({
        type: WEBSOCKET_RECEIVED_REPORT_SUCCESS,
        payload: action.message.arguments[0],
      });
      yield playSound();
    } else if (action.type === WEBSOCKET_OPENED) {
      yield call(doGetReports);
    }
  }
}

function* doStopReportsWebsocket() {
  if (channel && socketInterval) {
    clearInterval(socketInterval);
    channel.close();
  }
}

function* playSound() {
  const audio = new Audio("button_37c.mp3");
  yield audio.play();
}

function* doOnRefresh() {
  yield genericSagaHandler(GET_REPORTS_PROCESS, function* () {
    yield put({
      type: RESET_REPORTS_SEARCH_DATA,
    });
    yield put({
      type: GET_REPORTS_START,
    });
  });
}

export default function* getReportsSaga() {
  yield takeEvery("PLAY_SOUND", playSound);

  // yield takeEvery(REPORTS_PAGES_GET_PROCESS_START, doGetPagesReports);

  // new actions
  yield takeEvery(GET_REPORT_BY_ID_START, doGetReportById);
  yield takeEvery(GET_COMPANY_REPORTS_START, doGetCompanyReports);
  yield takeEvery(SET_COMPANY_REPORTS_PAGE_START, doGetCompanyReports);
  yield takeEvery(SET_COMPANY_REPORTS_PREVIOUS_PAGE_START, doGetCompanyReports);

  // yield takeEvery(SET_REPORTS_SEARCH_DATA, doGetReports);
  yield takeEvery(GET_REPORTS_START, doGetReports);
  yield takeEvery(SET_REPORTS_PAGE_START, doGetReports);

  yield takeEvery(START_REPORTS_WEBSOCKET, doStartReportsWebsocket);
  yield takeEvery(STOP_REPORTS_WEBSOCKET, doStopReportsWebsocket);

  yield takeEvery(REPORTS_ON_REFRESH, doOnRefresh);
}
