import { call, put, select, takeEvery } from 'redux-saga/effects';
import {
  GET_CANDLES_PROCESS,
  GET_CANDLES_PROCESS_SUCCESS,
  GET_CANDLES_START,
  SET_CANDLES_SEARCH_DATA_PROCESS,
  SET_CANDLES_SEARCH_DATA_START,
} from '../actions/ChartActions';
import genericSagaHandler from './CommonSaga';
import { getCandles } from '../services/CandleService';
import { MARKET } from '../config/constants';

function* doGetCandles({ payload }) {
  yield genericSagaHandler(GET_CANDLES_PROCESS, function* () {
    const { dateTo, dateFrom, candleType, size, symbol } = payload;
    const { data } = yield getCandles({
      Page: 0,
      PageSize: size,
      Market: MARKET,
      Symbol: symbol,
      CandleType: candleType,
      TimeFrom: dateFrom,
      TimeTo: dateTo,
    });
    console.log('doGetCandles', data);
    yield put({
      type: GET_CANDLES_PROCESS_SUCCESS,
      payload: data.items,
    });
  });
}

export default function* ChartSagas() {
  yield takeEvery(GET_CANDLES_START, doGetCandles);
}
