import React, { useCallback, useEffect, useState } from "react";
import { useRef } from "react";
import Draggable from "react-draggable";
import { useDispatch, useSelector } from "react-redux";
import {
  INIT_CHAT_WEBSOCKET,
  POST_CHAT_MESSAGE_START,
} from "../actions/ChatActions";
import { GET_NICK_START } from "../actions/LoginActions";
import {
  MAIN_DARK_COLOR,
  MAIN_DARK_COLOR_LIGHTER,
  MAIN_LIGHT_COLOR,
} from "../config/colors";
import AuthorChatBubble from "./chat/AuthorChatBubble";
import UserChatBubble from "./chat/UserChatBubble";

const ChatWidget = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(({ LoginReducer }) => LoginReducer.token);
  const nick = useSelector(({ LoginReducer }) => LoginReducer.nick);
  const [newMessage, setNewMessage] = useState("");
  const [collapsed, setCollapsedState] = useState();

  const [defaultPosition, setDefaultPosition] = useState({ x: 0, y: 0 });

  const setCollapsed = (val) => {
    setCollapsedState(val);
    localStorage.setItem("chat-collapsed", val);
  };

  useEffect(() => {
    if (!nick && isAuthenticated) {
      dispatch({
        type: GET_NICK_START,
      });
    }
  }, [dispatch, nick, isAuthenticated]);

  useEffect(() => {
    const storedPosition = localStorage.getItem("last-drag-position");
    if (storedPosition) {
      console.log("storedPosition", JSON.parse(storedPosition));
      setDefaultPosition(JSON.parse(storedPosition));
    }

    setCollapsed(localStorage.getItem("chat-collapsed") === "true");
    dispatch({
      type: INIT_CHAT_WEBSOCKET,
    });
  }, [dispatch]);
  const nodeRef = useRef();

  const { messages } = useSelector(({ ChatReducer }) => ChatReducer);
  if (!messages || messages.length === 0) {
    return null;
  }
  if (!isAuthenticated) return null;

  if (collapsed) {
    const { message, sender, createdDate, id } = messages[0];
    return (
      <Draggable
        onStop={(e) => {
          // console.log("ref", );
          const data = nodeRef.current?.getBoundingClientRect();
          localStorage.setItem(
            "last-drag-position",
            JSON.stringify({ x: data.x, y: data.y })
          );
        }}
        nodeRef={nodeRef}
      >
        <div
          ref={nodeRef}
          style={{
            cursor: "grab",
            width: 300,
            height: 100,
            borderWidth: 1,
            borderColor: MAIN_DARK_COLOR_LIGHTER,
            borderStyle: "solid",
            padding: 10,
            borderRadius: 20,
            paddingTop: 0,
            display: "flex",
            justifyContent: "space-between",
            position: "absolute",
            left: defaultPosition?.x,
            top: defaultPosition?.y,
            backgroundColor: MAIN_LIGHT_COLOR,
          }}
        >
          {sender === nick && (
            <AuthorChatBubble
              key={`author_${id}`}
              message={message}
              sender={sender}
              date={createdDate}
            />
          )}
          {sender !== nick && (
            <UserChatBubble
              key={`user_${id}`}
              message={message}
              sender={sender}
              date={createdDate}
            />
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              padding: 10,
              paddingTop: 0,
            }}
          >
            <button
              onClick={() => setCollapsed(false)}
              style={{
                fontFamily: "Lato",
                fontSize: 38,
                fontWeight: "bold",
                color: MAIN_DARK_COLOR,
                background: "none",
                borderWidth: 0,
              }}
            >
              {"\u2304"}
            </button>
          </div>
        </div>
      </Draggable>
    );
  }

  return (
    <Draggable
      onStop={(e) => {
        const data = nodeRef.current?.getBoundingClientRect();
        localStorage.setItem(
          "last-drag-position",
          JSON.stringify({ x: data.x, y: data.y })
        );
      }}
      nodeRef={nodeRef}
    >
      <div
        ref={nodeRef}
        style={{
          width: 400,
          borderWidth: 1,
          borderColor: MAIN_DARK_COLOR_LIGHTER,
          borderStyle: "solid",
          padding: 10,
          borderRadius: 20,
          paddingTop: 0,
          position: "absolute",
          left: defaultPosition?.x,
          top: defaultPosition?.y,
          backgroundColor: MAIN_LIGHT_COLOR,
          cursor: "grab",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            padding: 10,
            paddingTop: 0,
          }}
        >
          <button
            onClick={() => setCollapsed(true)}
            style={{
              fontFamily: "Lato",
              fontSize: 38,

              fontWeight: "bold",
              color: MAIN_DARK_COLOR,
              background: "none",
              borderWidth: 0,
            }}
          >
            {"\u2303"}
          </button>
        </div>
        <div
          style={{
            maxHeight: 400,
            overflowY: "scroll",
          }}
        >
          {messages.map((item) => {
            const { message, sender, createdDate, id } = item;
            console.log("lukas", item.sender, nick);
            if (item.sender === nick) {
              return (
                <AuthorChatBubble
                  key={`author_${id}`}
                  message={message}
                  sender={sender}
                  date={createdDate}
                />
              );
            } else {
              return (
                <UserChatBubble
                  key={`user_${id}`}
                  message={message}
                  sender={sender}
                  date={createdDate}
                />
              );
            }
          })}
        </div>
        <div
          style={{
            flexDirection: "row",
            alignItems: "flex-start",
            justifyContent: "space-between",
            marginTop: 19,
            marginBottom: 10,
            paddingLeft: 5,
            paddingRight: 5,
            height: 40,
          }}
        >
          <div
            style={{
              flex: 1,
              marginRight: 10,
              paddingTop: 5,
              display: "flex",
              flexDirection: "row",
            }}
          >
            <input
              type="text"
              value={newMessage}
              style={{
                borderWidth: 1,
                borderColor: MAIN_DARK_COLOR_LIGHTER,
                flex: 1,
                borderRadius: 10,
                padding: 10,
                flex: 1,
              }}
              onChange={(e) => setNewMessage(e.currentTarget.value)}
              onSubmitEditing={() => {
                dispatch({
                  type: POST_CHAT_MESSAGE_START,
                  payload: newMessage,
                });
                setNewMessage("");
              }}
            />
            <button
              style={{
                backgroundColor: MAIN_DARK_COLOR,
                color: MAIN_LIGHT_COLOR,
                borderRadius: 10,
                borderWidth: 0,
                padding: 10,
                marginLeft: 10,
              }}
              onClick={() => {
                dispatch({
                  type: POST_CHAT_MESSAGE_START,
                  payload: newMessage,
                });
                setNewMessage("");
              }}
            >
              {"\u23CE "}
            </button>
          </div>
        </div>
      </div>
    </Draggable>
  );
};

export default ChatWidget;
