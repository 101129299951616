import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import {
  APPLY_THEME_ON_LAUNCH,
  SET_AUTO_MODE,
  SET_COLORS,
  SET_DARK_MODE,
  SET_LIGHT_MODE,
} from "../actions/ConfigActions";
import { colorModes } from "../config/colors";
import { ASYNC_STORAGE_KEYS, UI_MODES } from "../config/constants";

function* doSetDarkMode() {
  yield localStorage.setItem(ASYNC_STORAGE_KEYS.UI_MODE, UI_MODES.DARK);
}

function* doSetLightMode() {
  yield localStorage.setItem(ASYNC_STORAGE_KEYS.UI_MODE, UI_MODES.LIGHT);
}

function* doSetAutoMode() {
  yield localStorage.setItem(ASYNC_STORAGE_KEYS.UI_MODE, UI_MODES.AUTO);
  yield call(doApplyThemeOnLaunch, {});
}

function* doApplyThemeOnLaunch() {
  const uiModeFromStorage = localStorage.getItem(ASYNC_STORAGE_KEYS.UI_MODE);
  console.log("uiModeFromStorage", uiModeFromStorage);
  if (uiModeFromStorage === null || uiModeFromStorage === UI_MODES.AUTO) {
    const colorScheme = "dark";

    if (colorScheme === "dark") {
      yield put({
        type: SET_COLORS,
        payload: { ...colorModes.DARK, mode: UI_MODES.AUTO },
      });
    } else {
      yield put({
        type: SET_COLORS,
        payload: { ...colorModes.LIGHT, mode: UI_MODES.AUTO },
      });
    }
  } else if (uiModeFromStorage === UI_MODES.DARK) {
    yield put({
      type: SET_COLORS,
      payload: colorModes.DARK,
    });
  } else {
    yield put({
      type: SET_COLORS,
      payload: colorModes.LIGHT,
    });
  }
}

export default function* ConfigSagas() {
  yield takeEvery(SET_DARK_MODE, doSetDarkMode);
  yield takeEvery(SET_LIGHT_MODE, doSetLightMode);
  yield takeEvery(SET_AUTO_MODE, doSetAutoMode);
  yield takeLatest(APPLY_THEME_ON_LAUNCH, doApplyThemeOnLaunch);
}
