export const GET_COMPANY_INFORMATION_PROCESS_START =
  'GET_COMPANY_INFORMATION_PROCESS_START';
export const GET_COMPANY_INFORMATION_PROCESS_SUCCESS =
  'GET_COMPANY_INFORMATION_PROCESS_SUCCESS';
export const GET_COMPANY_INFORMATION_PROCESS_ERROR =
  'GET_COMPANY_INFORMATION_PROCESS_ERROR';

export const GET_TAB_COMPANIES_START = 'GET_TAB_COMPANIES_START';
export const GET_TAB_COMPANIES_PROCESS = 'GET_TAB_COMPANIES_PROCESS';
export const GET_TAB_COMPANIES_PROCESS_START =
  'GET_TAB_COMPANIES_PROCESS_START';
export const GET_TAB_COMPANIES_PROCESS_SUCCESS =
  'GET_TAB_COMPANIES_PROCESS_SUCCESS';
export const GET_TAB_COMPANIES_PROCESS_ERROR =
  'GET_TAB_COMPANIES_PROCESS_ERROR';

export const SET_TAB_COMPANIES_FILTER_START = 'SET_TAB_COMPANIES_FILTER_START';
export const SET_TAB_COMPANIES_FILTER_PROCESS =
  'SET_TAB_COMPANIES_FILTER_PROCESS';
export const SET_TAB_COMPANIES_FILTER_PROCESS_START =
  'SET_TAB_COMPANIES_FILTER_PROCESS_START';
export const SET_TAB_COMPANIES_FILTER_PROCESS_SUCCESS =
  'SET_TAB_COMPANIES_FILTER_PROCESS_SUCCESS';
export const SET_TAB_COMPANIES_FILTER_PROCESS_ERROR =
  'SET_TAB_COMPANIES_FILTER_PROCESS_ERROR';

export const GET_COMPANIES_START = 'GET_COMPANIES_START';
export const GET_COMPANIES_PROCESS = 'GET_COMPANIES_PROCESS';
export const GET_COMPANIES_PROCESS_START = 'GET_COMPANIES_PROCESS_START';
export const GET_COMPANIES_PROCESS_SUCCESS = 'GET_COMPANIES_PROCESS_SUCCESS';
export const GET_COMPANIES_PROCESS_ERROR = 'GET_COMPANIES_PROCESS_ERROR';
