import React from "react";
import { useSelector } from "react-redux";
import ChatWidget from "./ChatWidget";
import Footer from "./Footer";
import Header from "./Header";

const BasePage = ({ children }) => {
  const { MAIN_LIGHT_COLOR } = useSelector(
    ({ ConfigReducer }) => ConfigReducer.colors
  );
  return (
    <div
      style={{
        backgroundColor: MAIN_LIGHT_COLOR,
        flexDirection: "column",
        alignSelf: "center",
        display: "flex",
        minHeight: "100vh",
      }}
    >
      <Header />
      <ChatWidget />
      <div
        style={{
          flex: 1,

          alignSelf: "center",
          padding: 20,
          width: "100%",
          maxWidth: "100vh"
        }}
      >
        {children}
      </div>
      <Footer />
    </div>
  );
};

export default BasePage;
