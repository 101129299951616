export const RESET_ALL_ARTICLES_COMMENTS_PAGE_START =
  "RESET_ALL_ARTICLES_COMMENTS_PAGE_START";
export const SET_ALL_ARTICLES_COMMENTS_BY_ID_PAGE_START =
  "SET_ALL_ARTICLES_PAGE_START";

export const GET_ARTICLE_COMMENTS_BY_ID_PROCESS =
  "GET_ARTICLE_COMMENTS_BY_ID_PROCESS";
export const GET_ARTICLE_COMMENTS_BY_ID_START =
  "GET_ARTICLE_COMMENTS_BY_ID_START";
export const GET_ARTICLE_COMMENTS_BY_ID_PROCESS_START =
  "GET_ARTICLE_COMMENTS_BY_ID_PROCESS_START";
export const GET_ARTICLE_COMMENTS_BY_ID_PROCESS_SUCCESS =
  "GET_ARTICLE_COMMENTS_BY_ID_PROCESS_SUCCESS";
export const GET_ARTICLE_COMMENTS_BY_ID_PROCESS_ERROR =
  "GET_ARTICLE_COMMENTS_BY_ID_PROCESS_ERROR";

export const PUT_ARTICLE_COMMENTS_BY_ID_PROCESS =
  "PUT_ARTICLE_COMMENTS_BY_ID_PROCESS";
export const PUT_ARTICLE_COMMENTS_BY_ID_START =
  "PUT_ARTICLE_COMMENTS_BY_ID_START";
export const PUT_ARTICLE_COMMENTS_BY_ID_PROCESS_START =
  "PUT_ARTICLE_COMMENTS_BY_ID_PROCESS_START";
export const PUT_ARTICLE_COMMENTS_BY_ID_PROCESS_SUCCESS =
  "PUT_ARTICLE_COMMENTS_BY_ID_PROCESS_SUCCESS";
export const PUT_ARTICLE_COMMENTS_BY_ID_PROCESS_ERROR =
  "PUT_ARTICLE_COMMENTS_BY_ID_PROCESS_ERROR";

export const RESET_ALL_COMMENTS_BY_ID_START = "RESET_ALL_COMMENTS_BY_ID_START";
