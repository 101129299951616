import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { get } from "lodash";
import { i18n } from "../config/i18n";

const ErrorAlertComponent = ({ processName, callback, excluded = [] }) => {
  const dispatch = useDispatch();
  const processUsed = useSelector(({ ErrorReducer }) => {
    if (processName === "*") {
      const errorKeys = Object.keys(ErrorReducer);
      return errorKeys[0];
    } else if (processName === "GENERIC") {
      // this is just a hack to support temporary generic error
      return "GENERIC";
    } else {
      return processName;
    }
  });

  const error = useSelector(({ ErrorReducer }) => {
    return ErrorReducer[processUsed];
  });

  const code = get(error, ["code"]);

  const message = i18n.ERRORS[code]
    ? i18n.ERRORS[code]
    : i18n.ERRORS.GENERIC + ` ${i18n.ERRORS.CODE} ${code}`;

  useEffect(() => {
    if (message && code) {
      alert(message);
      if (callback) {
        callback();
      }
      dispatch({ type: `${processName}_RESET` });
    }
  }, [message, code]);

  return null;
};

export default ErrorAlertComponent;
