import HttpService from './HttpService';

const LoginService = {
  login: ({ email, password }) => {
    console.log(email, password);
    return HttpService.post('login', { email, password });
  },

  refreshToken: ({ refreshToken, token }) => {
    return HttpService.post('login/refresh-token', { refreshToken, token });
  },

  getNick: ({ email }) => {
    return HttpService.get(`accounts?User=${email?.toLowerCase()}`);
  },
};

export default LoginService;
