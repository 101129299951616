import {
  SET_AUTO_MODE,
  SET_COLORS,
  SET_DARK_MODE,
  SET_LIGHT_MODE,
  SET_REPORTS_CONFIG,
} from "../actions/ConfigActions";
import {
  colorModes,
  GREEN_COLOR,
  GREEN_COLOR_LIGHTER,
  GRID_LINE_COLOR,
  LINK_COLOR,
  MAIN_DARK_COLOR,
  MAIN_DARK_COLOR_LIGHTER,
  MAIN_LIGHT_COLOR,
  MAIN_SHADOW_COLOR,
  RED_COLOR,
  RED_COLOR_LIGHTER,
  YELLOW_COLOR,
  YELLOW_COLOR_LIGHTER,
} from "../config/colors";
import { UI_MODES } from "../config/constants";

const ConfigReducer = (
  state = {
    reportsConfig: {},
    colors: {
      MAIN_LIGHT_COLOR,
      MAIN_DARK_COLOR,
      MAIN_DARK_COLOR_LIGHTER,
      MAIN_SHADOW_COLOR,
      GRID_LINE_COLOR,
      RED_COLOR,
      RED_COLOR_LIGHTER,
      GREEN_COLOR,
      GREEN_COLOR_LIGHTER,
      YELLOW_COLOR,
      YELLOW_COLOR_LIGHTER,
      LINK_COLOR,
      barStyle: "dark-content",
    },
  },
  action
) => {
  switch (action.type) {
    case SET_REPORTS_CONFIG:
      return {
        ...state,
        reportsConfig: {
          ...state.reportsConfig,
          filename: action?.payload?.filename,
          soundName: action?.payload?.soundName,
        },
      };
    case SET_DARK_MODE:
      return {
        ...state,
        colors: {
          ...state.colors,
          ...colorModes.DARK,
          barStyle: "light-content",
          mode: UI_MODES.DARK,
        },
      };
    case SET_LIGHT_MODE:
      return {
        ...state,
        colors: {
          ...state.colors,
          ...colorModes.LIGHT,
          barStyle: "dark-content",
          mode: UI_MODES.LIGHT,
        },
      };
    case SET_AUTO_MODE: {
      return {
        ...state,
        colors: {
          ...state.colors,
          mode: UI_MODES.AUTO,
        },
      };
    }
    case SET_COLORS:
      return {
        ...state,
        colors: {
          ...state.colors,
          ...action.payload,
        },
      };
    default:
      return {
        ...state,
      };
  }
};

export default ConfigReducer;
