import { clone, find } from "lodash";
import {
  GET_COMPANY_ALERTS_PROCESS_SUCCESS,
  RESET_COMPANY_ALERTS_PAGE_START,
  SET_COMPANY_ALERTS_PAGE_START,
  SET_COMPANY_ALERTS_PREVIOUS_PAGE_START,
} from "../actions/AnalyzerAlertsActions";

const CompanyAlertsReducer = (state = { page: 1, items: {} }, action) => {
  switch (action.type) {
    case GET_COMPANY_ALERTS_PROCESS_SUCCESS:
      const { payload } = action;
      const { symbol, items, hasNextPage } = payload;

      return {
        ...state,
        items: {
          [symbol]: items,
        },
        hasNextPage: hasNextPage,
      };

    case RESET_COMPANY_ALERTS_PAGE_START:
      return {
        ...state,
        page: 1,
      };
    case SET_COMPANY_ALERTS_PAGE_START:
      return {
        ...state,
        page: state.page + 1,
      };

    case SET_COMPANY_ALERTS_PREVIOUS_PAGE_START:
      return {
        ...state,
        page: state.page - 1,
      };
    default:
      return {
        ...state,
      };
  }
};
export default CompanyAlertsReducer;
