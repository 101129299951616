import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { GET_COMPANIES_START } from "../actions/CompanyActions";

const MainWrapper = ({ children }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: GET_COMPANIES_START,
    });
  }, []);
  return children;
};

export default MainWrapper;
