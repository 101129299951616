import { find, findIndex } from "lodash";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BasePage from "../components/BasePage";
import CandleChartView from "../components/CandleChartView";
import CandleRangePicker from "../components/CandleRangePicker";
import CompanyLogo from "../components/CompanyLogo";
import CompanyReports from "../components/CompanyReports";
import StockPriceChange from "../components/StockPriceChange";
import VolumeBarChart from "../components/VolumeBarChart";
import useCompanyName from "../hooks/useCompanyName";
import update from "immutability-helper";
import Tabs from "../components/Tabs";
import { i18n } from "../config/i18n";
import CompanyAlerts from "../components/CompanyAlerts";

const CompanyDetailsRoute = () => {
  const { symbol } = useParams();
  const name = useCompanyName(symbol);
  const [rangeItems, setRangeItems] = useState([
    { id: "1W", name: "1W", value: 7, selected: true },
    { id: "1M", name: "1M", value: 30 },
    { id: "1Y", name: "1Y", value: 365 },
    { id: "MAX", name: "MAX", value: 1000 },
  ]);

  const [tabs, setTabs] = useState([
    {
      id: "reports",
      name: i18n.COMPANY_REPORTS,
      component: <CompanyReports symbol={symbol} />,
      selected: true,
    },
    {
      id: "alerts",
      name: i18n.COMPANY_ALERTS,
      component: <CompanyAlerts symbol={symbol} />,
    },
  ]);
  return (
    <BasePage>
      <div className="companyDetailsContainer">
        <div
          style={{
            flex: 0.7,
            alignItems: "center",
            justifyContent: "center",
          }}
          className="chartContainer"
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h1>{name?.name?.name}</h1>
            <CompanyLogo symbol={symbol} />
          </div>
          <StockPriceChange symbol={symbol} rangeItems={rangeItems} />
          <CandleChartView
            symbol={symbol}
            rangeItems={rangeItems}
            height={350}
          />
          <CandleRangePicker
            selectedCallback={(id) => {
              console.log(id);
              const selectedIndex = findIndex(rangeItems, (it) => it.selected);
              const newIndex = findIndex(rangeItems, (it) => it.id === id);
              const newRange = update(rangeItems, {
                [selectedIndex]: {
                  selected: {
                    $set: false,
                  },
                },
                [newIndex]: {
                  selected: {
                    $set: true,
                  },
                },
              });

              setRangeItems(newRange);
            }}
            items={rangeItems}
          />
          <VolumeBarChart
            symbol={symbol}
            rangeItems={rangeItems}
            height={150}
          />
        </div>

        <div style={{ flex: 0.3 }}>
          <Tabs
            onPress={(id) => {
              const selectedIndex = tabs.findIndex((it) => it.selected);
              const newSelectedTabIndex = tabs.findIndex((it) => it.id === id);

              const newTabs = update(tabs, {
                [selectedIndex]: {
                  selected: { $set: false },
                },
                [newSelectedTabIndex]: {
                  selected: { $set: true },
                },
              });

              setTabs(newTabs);
            }}
            tabs={tabs}
          />
        </div>
      </div>
    </BasePage>
  );
};

export default CompanyDetailsRoute;
