import HttpService from "./HttpService"

export const getReportDownloads = ({ reportId }) => {
    return HttpService.get(`report-attachments?ReportId=${reportId}`);
}

export const getReportDownloadContent = ({ reportId }) => {

    return HttpService.get(`report-attachments/${reportId}.pdf`, {
        responseType: 'arraybuffer',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/pdf'
        }
    });
}