const {
  GET_TAB_COMPANIES_PROCESS_SUCCESS,
  SET_TAB_COMPANIES_FILTER_PROCESS_SUCCESS,
} = require('../actions/CompanyActions');

const CompaniesTabReducer = (state = { items: [], filter: '' }, action) => {
  switch (action.type) {
    case GET_TAB_COMPANIES_PROCESS_SUCCESS:
      return {
        ...state,
        items: action?.payload?.items,
      };
    case SET_TAB_COMPANIES_FILTER_PROCESS_SUCCESS:
      return {
        ...state,
        filter: action?.payload,
      };
    default:
      return {
        ...state,
      };
  }
};
export default CompaniesTabReducer;
