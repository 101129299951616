import axios from "axios";
import Config from "../config/Config";
import xhrAdapter from "axios/lib/adapters/xhr";
import set from "lodash/set";

const httpInstance = axios.create({
  adapter: xhrAdapter,
  baseURL: Config.BASE_URL,
  responseType: "json",
  headers: {
    "Content-Type": "application/json",
    "x-api-key": Config.API_KEY,
    "api-version": 1,
    "x-device-type": "DESKTOP",
    "x-device-model": "BROWSER",
    "x-device-app-version": "1.0.0",
  },
});

const setToken = (token) => {
  set(httpInstance, "defaults.headers.common.Authorization", `Bearer ${token}`);
};

export default {
  setToken,
  httpInstance,
  get: httpInstance.get,
  post: httpInstance.post,
  put: httpInstance.put,
  delete: httpInstance.delete,
  patch: httpInstance.patch,
  options: httpInstance.options,
};
