export const INIT_CHAT_WEBSOCKET = 'INIT_CHAT_WEBSOCKET';

export const WEBSOCKET_RECEIVED_CHAT_MESSAGE_START =
  'WEBSOCKET_RECEIVED_CHAT_MESSAGE_START';
export const WEBSOCKET_RECEIVED_CHAT_MESSAGE_PROCESS =
  'WEBSOCKET_RECEIVED_CHAT_MESSAGE_PROCESS';
export const WEBSOCKET_RECEIVED_CHAT_MESSAGE_PROCESS_START =
  'WEBSOCKET_RECEIVED_CHAT_MESSAGE_PROCESS_START';
export const WEBSOCKET_RECEIVED_CHAT_MESSAGE_PROCESS_SUCCESS =
  'WEBSOCKET_RECEIVED_CHAT_MESSAGE_PROCESS_SUCCESS';
export const WEBSOCKET_RECEIVED_CHAT_MESSAGE_PROCESS_ERROR =
  'WEBSOCKET_RECEIVED_CHAT_MESSAGE_PROCESS_ERROR';

export const CHAT_WEBSOCKET_OPENED = 'CHAT_WEBSOCKET_OPENED';

export const POST_CHAT_MESSAGE_START = 'POST_CHAT_MESSAGE_START';
export const POST_CHAT_MESSAGE_PROCESS = 'POST_CHAT_MESSAGE_PROCESS';
export const POST_CHAT_MESSAGE_PROCESS_START =
  'POST_CHAT_MESSAGE_PROCESS_START';
export const POST_CHAT_MESSAGE_PROCESS_SUCCESS =
  'POST_CHAT_MESSAGE_PROCESS_SUCCESS';
export const POST_CHAT_MESSAGE_PROCESS_ERROR =
  'POST_CHAT_MESSAGE_PROCESS_ERROR';

export const RESET_CHAT_MESSAGES_START = 'RESET_CHAT_MESSAGES_START';
