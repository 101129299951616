// GET_REPORT_BY_ID_PROCESS_SUCCESS
import { get } from 'lodash';
import {
  GET_REPORT_BY_ID_PROCESS_SUCCESS,
  GET_REPORT_BY_ID_RESET,
} from '../actions/ReportsActions';

const ReportDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_REPORT_BY_ID_RESET:
      return {};
    case GET_REPORT_BY_ID_PROCESS_SUCCESS:
      const { payload } = action;
      return {
        ...state,
        ...payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default ReportDetailsReducer;
