import { call, put, takeEvery } from "@redux-saga/core/effects";
import {
  GET_NICK_PROCESS,
  GET_NICK_PROCESS_SUCCESS,
  GET_NICK_START,
  LOGOUT_PROCESS,
  LOGOUT_START,
  POST_LOGIN_PROCESS,
  POST_LOGIN_PROCESS_SUCCESS,
  POST_LOGIN_START,
  POST_REFRESH_TOKEN_PROCESS,
  POST_REFRESH_TOKEN_PROCESS_SUCCESS,
  POST_REFRESH_TOKEN_START,
} from "../actions/LoginActions";
import LoginService from "../services/LoginService";
// import localStorage from "@react-native-community/async-storage";
import HttpService from "../services/HttpService";
import genericSagaHandler from "./CommonSaga";
// import * as RootNavigation from "../../RootNavigation";
// import { HOME_TABS_ROUTE_NAME, LOGIN_ROUTE_NAME } from "../config/RouteNames";
import { ASYNC_STORAGE_KEYS } from "../config/constants";
import { select } from "redux-saga/effects";
import { STOP_REPORTS_WEBSOCKET } from "../actions/ReportsActions";

export function* doLogin({ payload }) {
  console.log("dologin");
  const { email, password } = payload;
  yield genericSagaHandler(POST_LOGIN_PROCESS, function* () {
    const { data } = yield LoginService.login({ email, password });
    const { token, refreshToken } = data;
    yield localStorage.setItem(ASYNC_STORAGE_KEYS.USER_TOKEN, token);
    yield localStorage.setItem(
      ASYNC_STORAGE_KEYS.USER_REFRESH_TOKEN,
      refreshToken
    );
    yield localStorage.setItem(ASYNC_STORAGE_KEYS.USER_EMAIL, email);
    HttpService.setToken(token);
    yield put({
      type: POST_LOGIN_PROCESS_SUCCESS,
      payload: { token, email },
    });
    yield call(doGetNick);
    // RootNavigation.reset(0, [{ name: HOME_TABS_ROUTE_NAME }]);
  });
}

function* doRetrieveToken() {
  yield genericSagaHandler(POST_REFRESH_TOKEN_PROCESS, function* () {
    const token = yield localStorage.getItem(ASYNC_STORAGE_KEYS.USER_TOKEN);
    const refreshToken = yield localStorage.getItem(
      ASYNC_STORAGE_KEYS.USER_REFRESH_TOKEN
    );
    const email = yield localStorage.getItem(ASYNC_STORAGE_KEYS.USER_EMAIL);
    if (token && refreshToken) {
      const { data } = yield LoginService.refreshToken({ token, refreshToken });
      const newToken = data.token;
      const newRefreshToken = data.refreshToken;
      yield localStorage.setItem(ASYNC_STORAGE_KEYS.USER_TOKEN, newToken);
      yield localStorage.setItem(
        ASYNC_STORAGE_KEYS.USER_REFRESH_TOKEN,
        newRefreshToken
      );
      HttpService.setToken(newToken);

      yield put({
        type: POST_REFRESH_TOKEN_PROCESS_SUCCESS,
        payload: { token: newToken, email },
      });
      yield call(doGetNick);
    }
  });
}

function* performRedirectionIfAllowed() {
  const { token } = yield select(({ LoginReducer }) => LoginReducer);
  // const currentRouteName =
  //   RootNavigation.navigationRef?.current?.getCurrentRoute();
  // if (token && currentRouteName.name === LOGIN_ROUTE_NAME) {
  //   RootNavigation.reset(0, [{ name: HOME_TABS_ROUTE_NAME }]);
  // }
}

function* doLogout() {
  yield genericSagaHandler(LOGOUT_PROCESS, function* () {
    yield clearData();
    yield put({
      type: STOP_REPORTS_WEBSOCKET,
    });
    // RootNavigation.reset(0, [{ name: LOGIN_ROUTE_NAME }]);
  });
}

function* doGetNick() {
  yield genericSagaHandler(GET_NICK_PROCESS, function* () {
    const email = yield select(({ LoginReducer }) => LoginReducer.email);
    const { data } = yield LoginService.getNick({ email });
    yield put({
      type: GET_NICK_PROCESS_SUCCESS,
      payload: data?.items[0]?.nick,
    });
  });
}

function* clearData() {
  yield localStorage.removeItem(ASYNC_STORAGE_KEYS.USER_TOKEN);
  yield localStorage.removeItem(ASYNC_STORAGE_KEYS.USER_REFRESH_TOKEN);
  yield localStorage.removeItem(ASYNC_STORAGE_KEYS.USER_EMAIL);
}

export default function* rootSaga() {
  yield takeEvery(POST_LOGIN_START, doLogin);
  yield takeEvery(POST_REFRESH_TOKEN_START, doRetrieveToken);
  yield takeEvery(LOGOUT_START, doLogout);
  yield takeEvery(GET_NICK_START, doGetNick);
  yield takeEvery(
    "PERFORM_REDIRECTION_IF_ALLOWED",
    performRedirectionIfAllowed
  );
}
