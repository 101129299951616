import { PROCESS_CLEAR } from "../actions/CommonActions";

const ProcessReducer = (state = {}, action) => {
  const { type } = action;
  const processState = type.substr(type.lastIndexOf("_") + 1);
  const processName = type.substr(0, type.lastIndexOf("_"));
  console.log(action);
  switch (type) {
    case PROCESS_CLEAR:
      return {};
    default:
      return {
        ...state,
        [processName]: processState,
      };
  }
};

export default ProcessReducer;
