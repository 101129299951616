import {
  LOGIN_REQUEST_ERROR,
  LOGIN_REQUEST_PROCESS_START,
  LOGOUT_START,
  LOGOUT_REQUEST,
  POST_LOGIN_PROCESS_SUCCESS,
  POST_REFRESH_TOKEN_PROCESS_SUCCESS,
  GET_NICK_PROCESS_SUCCESS,
} from '../actions/LoginActions';

const LoginReducer = (state = {}, action) => {
  switch (action.type) {
    case POST_REFRESH_TOKEN_PROCESS_SUCCESS:
    case POST_LOGIN_PROCESS_SUCCESS:
      const { token, email } = action.payload;
      return {
        token,
        email,
      };
    case LOGIN_REQUEST_ERROR:
      return {
        error: action.payload,
      };
    case LOGOUT_START:
    case LOGIN_REQUEST_PROCESS_START:
    case LOGOUT_REQUEST:
      return {};
    case GET_NICK_PROCESS_SUCCESS:
      return {
        ...state,
        nick: action.payload,
      };
    default:
      return state;
  }
};
export default LoginReducer;
