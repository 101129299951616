import dayjs from "dayjs";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  GET_ARTICLE_COMMENTS_BY_ID_START,
  SET_ALL_ARTICLES_COMMENTS_BY_ID_PAGE_START,
} from "../actions/ArticlesCommentsActions";
import { MAIN_DARK_COLOR, MAIN_LIGHT_COLOR } from "../config/colors";
import { i18n } from "../config/i18n";

const CommentsList = ({ articleId }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: GET_ARTICLE_COMMENTS_BY_ID_START,
      payload: { articleId },
    });
  }, [articleId, dispatch]);

  const comments = useSelector(
    ({ ArticleCommentsReducer }) => ArticleCommentsReducer[articleId]?.items
  );

  const hasNext = useSelector(
    ({ ArticleCommentsReducer }) => ArticleCommentsReducer[articleId]?.hasNext
  );

  return (
    <div>
      <h2>{i18n.COMMENTS}</h2>
      <ul>
        {comments &&
          comments.map((comment) => (
            <li key={`komentarz_${comment.id}`}>
              <div>
                <h5
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span>
                    {i18n.AUTHOR} {comment.ownerNick}
                  </span>
                  <span>
                    {i18n.DATE}
                    {dayjs(comment.createdDate).format("DD.MM.YY HH:mm")}
                  </span>
                </h5>
                <p>{comment.content}</p>
              </div>
            </li>
          ))}
        {hasNext && (
          <button
            style={{
              backgroundColor: MAIN_DARK_COLOR,
              color: MAIN_LIGHT_COLOR,
              border: "none",
              padding: "10px",
              margin: "10px",
              borderRadius: "10px",
              alignSelf: "center",
            }}
            onClick={() => {
              dispatch({
                type: SET_ALL_ARTICLES_COMMENTS_BY_ID_PAGE_START,
                payload: { articleId },
              });
            }}
          >
            {i18n.LOAD_MORE_COMMENTS}
          </button>
        )}
        {!comments && <p>{i18n.NO_COMMENTS}</p>}
      </ul>
    </div>
  );
};

export default CommentsList;
