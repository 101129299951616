import React from "react";
import BasePage from "../components/BasePage";
import { i18n } from "../config/i18n";

const TermsRoute = () => {
  return (
    <BasePage>
      <h1>{i18n.TERMS}</h1>

      <p>Regulamin dostępny pod adresem: <a href="https://www.stockup.cloud/regulations/termsConditions" target="_blank" rel="noopener noreferrer">www.stockup.cloud/regulations/termsConditions .</a></p>
      
    </BasePage>
  );
};

export default TermsRoute;
