export const GET_FAVORITES_PROCESS = 'GET_FAVORITES_PROCESS';
export const GET_FAVORITES_START = 'GET_FAVORITES_START';
export const GET_FAVORITES_PROCESS_SUCCESS = 'GET_FAVORITES_PROCESS_SUCCESS';
export const GET_FAVORITES_PROCESS_ERROR = 'GET_FAVORITES_PROCESS_ERROR';
export const GET_FAVORITES_PROCESS_START = 'GET_FAVORITES_PROCESS_START';

export const GET_COMPANY_FAVORITES_PROCESS = 'GET_COMPANY_FAVORITES_PROCESS';
export const GET_COMPANY_FAVORITES_START = 'GET_COMPANY_FAVORITES_START';
export const GET_COMPANY_FAVORITES_PROCESS_SUCCESS =
  'GET_COMPANY_FAVORITES_PROCESS_SUCCESS';
export const GET_COMPANY_FAVORITES_PROCESS_ERROR =
  'GET_COMPANY_FAVORITES_PROCESS_ERROR';
export const GET_COMPANY_FAVORITES_PROCESS_START =
  'GET_COMPANY_FAVORITES_PROCESS_START';

export const POST_FAVORITES_PROCESS = 'POST_FAVORITES_PROCESS';
export const POST_FAVORITES_START = 'POST_FAVORITES_START';
export const POST_FAVORITES_PROCESS_SUCCESS = 'POST_FAVORITES_PROCESS_SUCCESS';
export const POST_FAVORITES_PROCESS_ERROR = 'POST_FAVORITES_PROCESS_ERROR';
export const POST_FAVORITES_PROCESS_START = 'POST_FAVORITES_PROCESS_START';

export const PATCH_FAVORITES_PROCESS = 'PATCH_FAVORITES_PROCESS';
export const PATCH_FAVORITES_START = 'PATCH_FAVORITES_START';
export const PATCH_FAVORITES_PROCESS_SUCCESS =
  'PATCH_FAVORITES_PROCESS_SUCCESS';
export const PATCH_FAVORITES_PROCESS_ERROR = 'PATCH_FAVORITES_PROCESS_ERROR';
export const PATCH_FAVORITES_PROCESS_START = 'PATCH_FAVORITES_PROCESS_START';

export const DELETE_FAVORITES_PROCESS = 'DELETE_FAVORITES_PROCESS';
export const DELETE_FAVORITES_START = 'DELETE_FAVORITES_START';
export const DELETE_FAVORITES_PROCESS_SUCCESS =
  'DELETE_FAVORITES_PROCESS_SUCCESS';
export const DELETE_FAVORITES_PROCESS_ERROR = 'DELETE_FAVORITES_PROCESS_ERROR';
export const DELETE_FAVORITES_PROCESS_START = 'DELETE_FAVORITES_PROCESS_START';

export const RESET_FAVORITES_START = 'RESET_FAVORITES_START';
export const RESET_FAVORITES_PAGE_START = 'RESET_FAVORITES_PAGE_START';
export const SET_FAVORITES_PAGE_START = 'SET_FAVORITES_PAGE_START';

export const GET_FAVORITES_CATEGORIES_PROCESS =
  'GET_FAVORITES_CATEGORIES_PROCESS';
export const GET_FAVORITES_CATEGORIES_START = 'GET_FAVORITES_CATEGORIES_START';
export const GET_FAVORITES_CATEGORIES_PROCESS_SUCCESS =
  'GET_FAVORITES_CATEGORIES_PROCESS_SUCCESS';
export const GET_FAVORITES_CATEGORIES_PROCESS_ERROR =
  'GET_FAVORITES_CATEGORIES_PROCESS_ERROR';
export const GET_FAVORITES_CATEGORIES_PROCESS_START =
  'GET_FAVORITES_CATEGORIES_PROCESS_START';

export const POST_FAVORITES_CATEGORIES_PROCESS =
  'POST_FAVORITES_CATEGORIES_PROCESS';
export const POST_FAVORITES_CATEGORIES_START =
  'POST_FAVORITES_CATEGORIES_START';
export const POST_FAVORITES_CATEGORIES_PROCESS_SUCCESS =
  'POST_FAVORITES_CATEGORIES_PROCESS_SUCCESS';
export const POST_FAVORITES_CATEGORIES_PROCESS_ERROR =
  'POST_FAVORITES_CATEGORIES_PROCESS_ERROR';
export const POST_FAVORITES_CATEGORIES_PROCESS_START =
  'POST_FAVORITES_CATEGORIES_PROCESS_START';

export const DELETE_FAVORITES_CATEGORIES_PROCESS =
  'DELETE_FAVORITES_CATEGORIES_PROCESS';

export const DELETE_FAVORITES_CATEGORIES_PROCESS_RESET =
  'DELETE_FAVORITES_CATEGORIES_PROCESS_RESET';
export const DELETE_FAVORITES_CATEGORIES_START =
  'DELETE_FAVORITES_CATEGORIES_START';
export const DELETE_FAVORITES_CATEGORIES_PROCESS_SUCCESS =
  'DELETE_FAVORITES_CATEGORIES_PROCESS_SUCCESS';
export const DELETE_FAVORITES_CATEGORIES_PROCESS_ERROR =
  'DELETE_FAVORITES_CATEGORIES_PROCESS_ERROR';
export const DELETE_FAVORITES_CATEGORIES_PROCESS_START =
  'DELETE_FAVORITES_CATEGORIES_PROCESS_START';

export const SET_FAVORITES_SELECTED_CATEGORY_PROCESS =
  'SET_FAVORITES_SELECTED_CATEGORY_PROCESS';
export const SET_FAVORITES_SELECTED_CATEGORY_START =
  'SET_FAVORITES_SELECTED_CATEGORY_START';
export const SET_FAVORITES_SELECTED_CATEGORY_PROCESS_SUCCESS =
  'SET_FAVORITES_SELECTED_CATEGORY_PROCESS_SUCCESS';
export const SET_FAVORITES_SELECTED_CATEGORY_PROCESS_ERROR =
  'SET_FAVORITES_SELECTED_CATEGORY_PROCESS_ERROR';
export const SET_FAVORITES_SELECTED_CATEGORY_PROCESS_START =
  'SET_FAVORITES_SELECTED_CATEGORY_PROCESS_START';

export const RESET_FAVORITE_NOTES = 'RESET_FAVORITE_NOTES';
export const GET_FAVORITE_NOTES_PROCESS = 'GET_FAVORITE_NOTES_PROCESS';
export const GET_FAVORITE_NOTES_START = 'GET_FAVORITE_NOTES_START';
export const GET_FAVORITE_NOTES_PROCESS_SUCCESS =
  'GET_FAVORITE_NOTES_PROCESS_SUCCESS';
export const GET_FAVORITE_NOTES_PROCESS_ERROR =
  'GET_FAVORITE_NOTES_PROCESS_ERROR';
export const GET_FAVORITE_NOTES_PROCESS_START =
  'GET_FAVORITE_NOTES_PROCESS_START';

export const POST_FAVORITE_NOTES_PROCESS = 'POST_FAVORITE_NOTES_PROCESS';
export const POST_FAVORITE_NOTES_START = 'POST_FAVORITE_NOTES_START';
export const POST_FAVORITE_NOTES_PROCESS_SUCCESS =
  'POST_FAVORITE_NOTES_PROCESS_SUCCESS';
export const POST_FAVORITE_NOTES_PROCESS_ERROR =
  'POST_FAVORITE_NOTES_PROCESS_ERROR';
export const POST_FAVORITE_NOTES_PROCESS_START =
  'POST_FAVORITE_NOTES_PROCESS_START';

export const DELETE_FAVORITE_NOTES_PROCESS = 'DELETE_FAVORITE_NOTES_PROCESS';
export const DELETE_FAVORITE_NOTES_START = 'DELETE_FAVORITE_NOTES_START';
export const DELETE_FAVORITE_NOTES_PROCESS_SUCCESS =
  'DELETE_FAVORITE_NOTES_PROCESS_SUCCESS';
export const DELETE_FAVORITE_NOTES_PROCESS_ERROR =
  'DELETE_FAVORITE_NOTES_PROCESS_ERROR';
export const DELETE_FAVORITE_NOTES_PROCESS_START =
  'DELETE_FAVORITE_NOTES_PROCESS_START';

export const SET_FAVORITES_SORTING_PROCESS = 'SET_FAVORITES_SORTING_PROCESS';
export const SET_FAVORITES_SORTING_START = 'SET_FAVORITES_SORTING_START';
export const SET_FAVORITES_SORTING_PROCESS_START =
  'SET_FAVORITES_SORTING_PROCESS_START';
export const SET_FAVORITES_SORTING_PROCESS_ERROR =
  'SET_FAVORITES_SORTING_PROCESS_ERROR';
export const SET_FAVORITES_SORTING_PROCESS_SUCCESS =
  'SET_FAVORITES_SORTING_PROCESS_SUCCESS';

export const SET_FAVORITES_FILTER_PROCESS = 'SET_FAVORITES_FILTER_PROCESS';
export const SET_FAVORITES_FILTER_START = 'SET_FAVORITES_FILTER_START';
export const SET_FAVORITES_FILTER_PROCESS_START =
  'SET_FAVORITES_FILTER_PROCESS_START';
export const SET_FAVORITES_FILTER_PROCESS_ERROR =
  'SET_FAVORITES_FILTER_PROCESS_ERROR';
export const SET_FAVORITES_FILTER_PROCESS_SUCCESS =
  'SET_FAVORITES_FILTER_PROCESS_SUCCESS';
