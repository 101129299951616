export const ASYNC_STORAGE_KEYS = {
  USER_EMAIL: "@email",
  USER_TOKEN: "@token",
  USER_REFRESH_TOKEN: "@refreshToken",
  RESET_PASSWORD_EMAIL: "@resetPasswordEmail",
  PUSH_REQUESTED: "@pushRequested",
  UI_MODE: "@uiMode",
  ONBOARDING_SHOWN_FOR_VERSION: "@onBoardingShownForVersion",
  REPORTS_CONFIG: "@reportsConfig",
  LATEST_ARTICLE_ID: "@latestArticleId",
  CHAT_TERMS: "@chatTerms",
};

export const ALERT_MODES = {
  CUSTOM: "CUSTOM",
  CANDLES: "CANDLES",
  FORMATIONS: "FORMATIONS",
  REPORTS_ANALYZER: "REPORTS_ANALYZER",
};

export const analyzerTypeMapping = {
  CANDLES: "Candle",
  FORMATIONS: "CandlesFormation",
};

export const UI_MODES = {
  DARK: "DARK",
  LIGHT: "LIGHT",
  AUTO: "AUTO",
};

export const stockupPatroniteUrl = "https://patronite.pl/Stockup";

export const MARKET = "WSE";
export const REPORT_LINK =
  "http://infostrefa.com/infostrefa/pl/raporty/espi/biezace,0,0,0,1/";
export const NOTIFICATION_LINKS = "https://stockup.cloud/how-to-notifications";

export const DATE_FORMAT = "DD.MM.YY";
export const excludedDates = [
  "1.1.2020",
  "6.1.2020",
  "10.4.2020",
  "13.4.2020",
  "1.5.2020",
  "11.6,2020",
  "11.11.2020",
  "24.12.2020",
  "25.12.2020",
  "31.12.2020",
  "1.1.2021",
  "6.1.2021",
  "2.4.2021",
  "5.4.2021",
  "3.5.2021",
  "3.6.2021",
  "1.11.2021",
  "24.12.2021",
  "31.12.2021",
];
