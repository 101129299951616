import { get } from "lodash";
import { put } from "redux-saga/effects";
import {
  PROCESS_START,
  PROCESS_COMPLETE,
  PROCESS_ERROR,
  PROCESS_RESET,
} from "../actions/CommonActionTypes";

function* genericSagaHandler(processName, sagaProcessGeneratorFunction) {
  try {
    yield put({
      type: `${processName}_${PROCESS_RESET}`,
    });
    yield put({
      type: `${processName}_${PROCESS_START}`,
    });
    yield sagaProcessGeneratorFunction();
    yield put({
      type: `${processName}_${PROCESS_COMPLETE}`,
    });
  } catch (e) {
    console.log(e);
    const status = get(e, ["response", "status"]);
    const detail = get(e, ["response", "data", "detail"]);
    let errorPayload = detail || e || {};
    if (status === 204) {
      errorPayload = 204;
    }

    yield put({
      type: `${processName}_${PROCESS_ERROR}`,
      payload: {
        code: errorPayload,
      },
    });
  }
}

export default genericSagaHandler;
