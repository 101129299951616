import React from "react";
import ReactMarkdown from "react-markdown";
import { Link } from "react-router-dom";
import { i18n } from "../config/i18n";
import { MAIN_DARK_COLOR_LIGHTER } from "../config/colors";

const ArticleTeaser = ({ article }) => {
  const teaserContent =
    `${article?.content.split(" ").splice(0, 100).join(" ")}...` || "";

  return (
    <div
    style={{
      borderTopWidth: 1,
      borderTopStyle: "solid",
      borderTopColor: MAIN_DARK_COLOR_LIGHTER,
      paddingTop: 40,
      paddingBottom: 40,
    }}>
      <h1 className="markdown">{article.title}</h1>
      <ReactMarkdown className="markdown">{teaserContent}</ReactMarkdown>
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        <Link className="readMore" to={`/article/${article.id}`}>
          {i18n.MORE}
        </Link>
      </div>
    </div>
  );
};


export default ArticleTeaser;
