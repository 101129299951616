import { GET_COMPANY_FAVORITES_PROCESS_SUCCESS } from '../actions/FavoritesActions';
import update from 'immutability-helper';
import { findIndex } from 'lodash';

const { GET_COMPANIES_PROCESS_SUCCESS } = require('../actions/CompanyActions');

const CompaniesReducer = (state = { items: [] }, action) => {
  switch (action.type) {
    case GET_COMPANIES_PROCESS_SUCCESS:
      return {
        ...state,
        items: action.payload,
      };
    case GET_COMPANY_FAVORITES_PROCESS_SUCCESS:
      const {
        symbol,
        favoriteId,
        isFavorite,
        favoriteCompanyGroupId,
      } = action.payload;
      const companyIndex = findIndex(
        state.items,
        (it) => it?.symbol?.abbreviation === symbol
      );
      const newItems = update(state.items, {
        [companyIndex]: {
          isFavorite: { $set: isFavorite },
          favoriteId: { $set: favoriteId },
          favoriteCompanyGroupId: { $set: favoriteCompanyGroupId },
        },
      });

      return {
        ...state,
        items: newItems,
      };
    default:
      return {
        ...state,
      };
  }
};
export default CompaniesReducer;
