import { get } from "lodash";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GET_REPORT_DOWNLOADS_PROCESS_START } from "../actions/ReportDownloadsActions";
import MobileConfig from "../config/Config";
import { i18n } from "../config/i18n";

const ReportDownloads = ({ reportId }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: GET_REPORT_DOWNLOADS_PROCESS_START,
      payload: { reportId },
    });
  }, []);

  const downloads = useSelector(({ ReportDownloadsReducer }) =>
    get(ReportDownloadsReducer, [reportId])
  );

  console.log(reportId, downloads);
  if (!downloads) return null;
  return (
    <div>
      <h2>{i18n.ATTACHMENTS}</h2>
      <ul>
        {downloads &&
          downloads.map((download, index) => (
            <li key={`downloadItem_${download.id}`}>
              <a
                style={{ lineBreak: "anywhere" }}
                href={`${MobileConfig.BASE_URL}report-attachments/${download.id}.pdf?x-api-key=${MobileConfig.API_KEY}`}
              >
                {download.name}
              </a>
            </li>
          ))}
      </ul>
    </div>
  );
};
export default ReportDownloads;
