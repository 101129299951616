import React, { useState, useEffect } from "react";
import { find, get } from "lodash";
import { useSelector } from "react-redux";
import { i18n } from "../config/i18n";
import { useDispatch } from "react-redux";
import { GET_CANDLES_START } from "../actions/ChartActions";
import usePrevious from "../hooks/usePrevious";

const StockPriceChange = ({ symbol, rangeItems }) => {
  const [priceChange, setPriceChange] = useState();
  const dispatch = useDispatch();
  const candles = useSelector(({ ChartReducer }) => ChartReducer.items);
  const { MAIN_DARK_COLOR, GREEN_COLOR, RED_COLOR } = useSelector(
    ({ ConfigReducer }) => ConfigReducer.colors
  );

  const symbolData = useSelector(({ CompaniesReducer: { items } }) =>
    find(items, (it) => it?.symbol?.abbreviation === symbol)
  );

  const spanStyle = {
    fontFamily: "Lato",
    fontSize: 12,
    color: MAIN_DARK_COLOR,
  };
  const previousRange = usePrevious(rangeItems);

  useEffect(() => {
    const selectedRange = find(rangeItems, (it) => it.selected);
    const previousSelectedRange = find(previousRange, (it) => it.selected);
    if (
      candles &&
      previousSelectedRange &&
      selectedRange.value === previousSelectedRange.value
    ) {
      console.log(candles);
      const last = get(candles[1], ["openClose", "close"]);
      const current = get(candles[0], ["openClose", "close"]);
      const change = parseFloat(((current - last) / last) * 100).toFixed(2);
      setPriceChange(change);
    } else {
      const now = new Date();

      dispatch({
        type: GET_CANDLES_START,
        payload: {
          symbol,
          size: selectedRange.value,
          dateTo: new Date(),
          dateFrom: new Date(now.setDate(now.getDate() - selectedRange.value)),
          candleType: "Day",
        },
      });
    }
  }, [candles, rangeItems]);

  const stockPrice = parseFloat(
    get(candles, [0, "openClose", "close"])
  ).toFixed(4);

  let color = "#22cfff";
  if (priceChange > 0) {
    color = GREEN_COLOR;
  } else if (priceChange < 0) {
    color = RED_COLOR;
  }

  if (isNaN(stockPrice)) {
    return null;
  }

  const capitalization = symbolData?.companyDetails?.capitalizationStr;
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        marginTop: "10px",
      }}
    >
      <div style={{ display: "flex", flexDirection: "row" }}>
        <span style={spanStyle}>{i18n.PRICE_LABEL}</span>
        <span style={{ ...spanStyle, color }}>
          {stockPrice} {i18n.CURRENCY}
        </span>
        <span style={{ ...spanStyle, marginLeft: 10 }}>
          {i18n.COURSE_CHANGE_LABEL}
        </span>
        <span style={{ ...spanStyle, color }}>{priceChange}%</span>
        {capitalization && (
          <span style={{ ...spanStyle, marginLeft: 10 }}>
            {i18n.CAPITALIZATION}
            {capitalization}
          </span>
        )}
      </div>
    </div>
  );
};

export default StockPriceChange;
