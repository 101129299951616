const MobileConfig = {
  BASE_PORTAL_URL: "https://spa.stockup.cloud/",
  BASE_URL: "https://api.stockup.cloud/", //PROD
  BASE_WSS_URL: "wss://api.stockup.cloud",
  API_KEY: "4b43b9ae-acd1-4e66-85ec-93895f84dc34", // PROD
  // BASE_URL: 'http://174.138.102.169:5030/', //DEV
  // API_KEY: 'd0be5918-edd0-4877-b061-64a83aadd14c', // DEV
  MAX_PHRASES: 3,
};
export default MobileConfig;
