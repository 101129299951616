export const i18n = {
  HOME: "News",
  COMPANIES: "Spółki",
  ABOUT: "O Nas",
  ESPI: "Raporty Espi/Ebi",
  TERMS: "Regulamin",
  MORE: "Czytaj dalej...  ",
  LOGIN: "Zaloguj",
  FIND_COMPANY: "Wyszukaj spółkę",
  MORE_ARTICLES: "Więcej artykułów",
  PREVIOUS_PAGE: "Poprzednia strona",
  NEXT_PAGE: "Następna strona",
  COMPANY_NAME: "Nazwa spółki",
  MORE_REPORTS: "Załaduj więcej raportów",
  ATTACHMENTS: "Załączniki",
  LOGOUT: "Wyloguj",
  ADD_COMMENT: "Dodaj komentarz",
  ADD_COMMENT_BTN: "Dodaj",
  PASSWORD: "Hasło: ",
  EMAIL: "Email: ",
  COMMENTS: "Komentarze",
  LOAD_MORE_COMMENTS: "Załaduj więcej komentarzy",
  NO_COMMENTS: "Brak komentarzy",
  DATE: "Data: ",
  CHART_DATE_FORMAT: "D-M-YY",
  AUTHOR: "Autor: ",
  COMMENT_WAS_SENT: "Komentarz został dodany",
  REALTIME_REPORTS_AVAILABLE_LOGGED_IN_ONLY:
    "Aktualizacja raportów w czasie rzeczywistym dostępna tylko dla zalogowanych użytkowników",
  REALTIME_REPORTS_TURN_ON: "Włącz autoodświeżanie",
  REALTIME_REPORTS_TURN_OFF: "Wyłącz autoodświeżanie",
  COMPANY_REPORTS: "Raporty spółki",
  COMPANY_ALERTS: "Alerty",
  VOLUME: "Wolumen",
  PICK_RANGE: "Wybierz zakres: ",
  PRICE_LABEL: "Cena: ",
  CURRENCY: "zł",
  CAPITALIZATION: "Kapitalizacja: ",
  COURSE_CHANGE_LABEL: "Zmiana kursu: ",
  ERRORS: {
    GENERIC:
      "Wystąpił nieznany błąd, spróbuj ponownie później. Jeśli problem będzie się powtarzać daj nam znać na email kontakt@stockup.cloud.",
  },
};
