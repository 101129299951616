import { put, select, takeEvery } from "@redux-saga/core/effects";
import {
  GET_ALL_ARTICLES_PROCESS,
  GET_ALL_ARTICLES_PROCESS_SUCCESS,
  GET_ALL_ARTICLES_START,
  GET_ARTICLE_BY_ID_PROCESS,
  GET_ARTICLE_BY_ID_PROCESS_SUCCESS,
  GET_ARTICLE_BY_ID_START,
  GET_LATEST_ARTICLES_PROCESS,
  GET_LATEST_ARTICLES_PROCESS_SUCCESS,
  GET_LATEST_ARTICLES_START,
  SET_ALL_ARTICLES_PAGE_START,
} from "../actions/ArticlesActions";
import genericSagaHandler from "./CommonSaga";
import { getArticleById, getArticles } from "../services/ArticlesService";
import { filter, get } from "lodash";
import { ASYNC_STORAGE_KEYS } from "../config/constants";

const ARTICLE_STATE_TO_SHOW = "Finished";

function* getArticlesSaga() {
  yield genericSagaHandler(GET_ALL_ARTICLES_PROCESS, function* () {
    const page = yield select(({ ArticlesReducer }) => ArticlesReducer.page);

    const { data } = yield getArticles({
      Page: page,
      PageSize: 5,
      State: ARTICLE_STATE_TO_SHOW,
      OwnerShipType: "General",
    });

    const hasNext =
      filter(get(data, ["links"]), (item) => item.rel === "nextPage").length >
      0;
    yield put({
      type: GET_ALL_ARTICLES_PROCESS_SUCCESS,
      payload: {
        items: data?.items,
        hasNext,
      },
    });
  });
}

function* getLatestArticleSaga() {
  yield genericSagaHandler(GET_LATEST_ARTICLES_PROCESS, function* () {
    const { data } = yield getArticles({
      Page: 1,
      PageSize: 1,
      State: ARTICLE_STATE_TO_SHOW,
      OwnerShipType: "General",
    });

    const currentLatestArticle = yield localStorage.getItem(
      ASYNC_STORAGE_KEYS.LATEST_ARTICLE_ID
    );

    let isNew = false;
    let newArticleId = get(data, ["items", "0", "id"], "");
    if (currentLatestArticle !== newArticleId) {
      isNew = true;
    }

    yield localStorage.setItem(
      ASYNC_STORAGE_KEYS.LATEST_ARTICLE_ID,
      newArticleId.toString()
    );
    yield put({
      type: GET_LATEST_ARTICLES_PROCESS_SUCCESS,
      payload: { ...data?.items[0], isNew },
    });
  });
}

function* doGetArticleByIdSaga({ payload }) {
  yield genericSagaHandler(GET_ARTICLE_BY_ID_PROCESS, function* () {
    const { id } = payload;
    const { data } = yield getArticleById({ id });
    const article = data?.items[0];
    yield put({
      type: GET_ARTICLE_BY_ID_PROCESS_SUCCESS,
      payload: article,
    });
  });
}

export default function* ArticlesSagas() {
  yield takeEvery(GET_ALL_ARTICLES_START, getArticlesSaga);
  yield takeEvery(SET_ALL_ARTICLES_PAGE_START, getArticlesSaga);
  yield takeEvery(GET_LATEST_ARTICLES_START, getLatestArticleSaga);
  yield takeEvery(GET_ARTICLE_BY_ID_START, doGetArticleByIdSaga);
}
