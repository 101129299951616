import React, { useEffect, useState } from "react";
import BasePage from "../components/BasePage";
import { useDispatch, useSelector } from "react-redux";
import {
  LOGIN_REQUEST_PROCESS,
  POST_LOGIN_PROCESS,
  POST_LOGIN_START,
} from "../actions/LoginActions";
import { i18n } from "../config/i18n";
import {
  buttonStyle,
  inputLabelStyle,
  inputStyle,
} from "../config/CommonStyles";
import ErrorAlertComponent from "../components/ErrorAlertComponent";
import ErrorInlineComponent from "../components/ErrorInlineComponent";
import { useHistory } from "react-router";

const LoginRoute = () => {
  const dispatch = useDispatch();
  const token = useSelector(({ LoginReducer }) => LoginReducer.token);
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const history = useHistory();

  useEffect(() => {
    if (token) {
      history.goBack();
    }
  }, [token]);

  return (
    <BasePage>
      <div
        style={{
          display: "flex",
          flex: 1,
          justifyContent: "space-between",
          flexDirection: "column",
        }}
      >
        <h2 style={{ textAlign: "center" }}>{i18n.LOGIN}</h2>

        <div style={{ display: "flex", alignItems: "flex-start" }}>
          <label style={inputLabelStyle}>{i18n.EMAIL} </label>
          <input
            style={inputStyle}
            type="text"
            value={email}
            onChange={(e) => setEmail(e.currentTarget.value)}
          />
        </div>
        <div style={{ display: "flex" }}>
          <label style={inputStyle}>{i18n.PASSWORD} </label>
          <input
            style={inputStyle}
            type="password"
            value={password}
            onChange={(e) => setPassword(e.currentTarget.value)}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flex: 1,
          justifyContent: "flex-end",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <ErrorAlertComponent processName={POST_LOGIN_PROCESS} />
        <button
          style={buttonStyle}
          onClick={() => {
            dispatch({
              type: POST_LOGIN_START,
              payload: { email, password },
            });
          }}
        >
          {i18n.LOGIN}
        </button>
      </div>
    </BasePage>
  );
};

export default LoginRoute;
