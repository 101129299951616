import { channel } from "redux-saga";
import { put, take, takeEvery } from "redux-saga/effects";
import {
  GET_REPORT_DOWNLOADS_PROCESS_START,
  GET_REPORT_DOWNLOADS_PROCESS_SUCCESS,
  GET_REPORT_DOWNLOAD_ITEM_PROCESS_ERROR,
  GET_REPORT_DOWNLOAD_ITEM_PROCESS_START,
  GET_REPORT_DOWNLOAD_ITEM_PROCESS_SUCCESS,
} from "../actions/ReportDownloadsActions";
import { getReportDownloads } from "../services/ReportDownloadsService";

import { get } from "lodash";

function* doGetReportDownloads({ payload }) {
  try {
    console.log("doGetReportDownloads");
    const { reportId } = payload;

    const { data } = yield getReportDownloads({ reportId });

    yield put({
      type: GET_REPORT_DOWNLOADS_PROCESS_SUCCESS,
      payload: {
        downloads: get(data, ["items"]),
      },
    });
  } catch (e) {
    console.log(e);
  }
}

// function* doGetReportDownloadItem({ payload }) {
//   try {
//     const { id } = payload;
//     const toFile = `${RNFS.CachesDirectoryPath}/${id}.pdf`;
//     const token = yield localStorage.getItem("@token");
//     const fileDownload = yield RNFS.downloadFile({
//       fromUrl: `${Config.BASE_URL}report-attachments/${id}.pdf`,
//       headers: {
//         "Content-Type": "application/json",
//         "x-api-key": MobileConfig.API_KEY,
//         Authorization: `Bearer ${token}`,
//       },
//       toFile,
//       progressDivider: 1,
//       progressInterval: 10,
//       begin: () => {
//         console.log("progress", "begin");
//       },
//     }).promise;
//     if (fileDownload.statusCode !== 200) {
//       yield put({
//         type: GET_REPORT_DOWNLOAD_ITEM_PROCESS_ERROR,
//         payload: {
//           code: fileDownload.statusCode,
//         },
//       });
//     } else {
//       yield FileViewer.open(toFile);
//       yield put({
//         type: GET_REPORT_DOWNLOAD_ITEM_PROCESS_SUCCESS,
//       });
//     }
//   } catch (e) {
//     yield put({
//       type: GET_REPORT_DOWNLOAD_ITEM_PROCESS_ERROR,
//     });
//   }
// }

export default function* ReportDownloadsSagas() {
  yield takeEvery(GET_REPORT_DOWNLOADS_PROCESS_START, doGetReportDownloads);
  // yield takeEvery(
  //   GET_REPORT_DOWNLOAD_ITEM_PROCESS_START,
  //   doGetReportDownloadItem
  // );
}
