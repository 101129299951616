import React, { useEffect } from "react";
import ReactMarkdown from "react-markdown";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  GET_REPORTS_START,
  SET_REPORTS_PAGE_START,
  START_REPORTS_WEBSOCKET,
  STOP_REPORTS_WEBSOCKET,
  WEBSOCKET_RECEIVED_REPORT_START,
} from "../actions/ReportsActions";
import BasePage from "../components/BasePage";
import {
  MAIN_DARK_COLOR,
  MAIN_DARK_COLOR_LIGHTER,
  MAIN_LIGHT_COLOR,
  MAIN_DISABLED_COLOR,
} from "../config/colors";
import { i18n } from "../config/i18n";

const EspiRoute = () => {
  const dispatch = useDispatch();

  const reports = useSelector(
    ({ AllReportsReducer }) => AllReportsReducer.items
  );

  const hasNextPage = useSelector(
    ({ AllReportsReducer }) => AllReportsReducer.hasNextPage
  );

  const isAuthenticated = useSelector(({ LoginReducer }) => LoginReducer.token);
  const isWebsocketRunning = useSelector(
    ({ AllReportsReducer }) => AllReportsReducer.isWebsocketRunning
  );

  useEffect(() => {
    dispatch({
      type: GET_REPORTS_START,
    });
  }, []);

  return (
    <BasePage>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h1>Raporty Espi/Ebi</h1>
        <p>Automatycznie odświeżająca się strona dostępna obecnie jedynie na <a href="https://www.stockup.cloud">starej stronie</a> - w dziale raportów po wykupieniu subskrypcji.</p>
  
      </div>
      {reports.map((report, idx) => (
        <div
          style={{
            borderTopWidth: idx === 0 ? 0 : 1,
            borderTopStyle: "solid",
            borderTopColor: MAIN_DARK_COLOR_LIGHTER,
            paddingTop: 40,
            paddingBottom: 40,
          }}
          key={report.id}
        >
          <h1><Link to={`/companies/${report?.stockSymbol?.abbreviation}`}
           style={{ 
               color: MAIN_DARK_COLOR, 
                textDecoration: 'none'  
                }}>{report?.companyName?.name}</Link></h1>
          <h2>
            {report?.dateTime} - {report?.title?.content}
          </h2>
          <ReactMarkdown>
            {report?.content?.content.split(" ").splice(0, 100).join(" ")}
          </ReactMarkdown>
          <Link to={`/espi/${report.id}`}>{i18n.MORE}</Link>
        </div>
      ))}
      {hasNextPage && (
        <button
          style={{
            marginTop: "20px",
            marginBottom: "20px",
            border: "none",
            background: "none",
            color: MAIN_DARK_COLOR,
            fontWeight: "bold",
            fontSize: "1.2rem",
          }}
          onClick={() =>
            dispatch({
              type: SET_REPORTS_PAGE_START,
            })
          }
        >
          {i18n.MORE_REPORTS}
        </button>
      )}
    </BasePage>
  );
};

export default EspiRoute;
