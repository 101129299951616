import HttpService from "./HttpService";

export const getArticles = ({ OwnerShipType, State, Page, PageSize }) =>
  HttpService.get(
    `/articles?State=${State}&Ownership.OwnershipType=${OwnerShipType}&IncludeChildren=true&Page=${Page}&PageSize=${PageSize}`
  );

export const getArticleById = ({ id }) =>
  HttpService.get(
    `/articles?Id=${id}&State=Finished&Ownership.OwnershipType=General`
  );
