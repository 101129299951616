import React from "react";
import { useSelector } from "react-redux";
import { i18n } from "../config/i18n";

const CandleRangePicker = ({ items, selectedCallback }) => {
  const { MAIN_DARK_COLOR, MAIN_DARK_COLOR_LIGHTER, MAIN_LIGHT_COLOR } =
    useSelector(({ ConfigReducer }) => ConfigReducer.colors);
  return (
    <div
      style={{
        flexDirection: "row",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
      }}
    >
      <span>{i18n.PICK_RANGE}</span>
      {items.map((it) => {
        const selected = it.selected;
        return (
          <button
            key={`range_${it.id}`}
            onClick={() => selectedCallback(it.id)}
            style={{
              marginLeft: 7,
              marginRight: 8,
              borderRadius: 45,
              backgroundColor: selected ? MAIN_DARK_COLOR : null,
              paddingLeft: 6,
              paddingRight: 6,
              paddingTop: 2,
              paddingBottom: 2,
            }}
          >
            <span
              style={{
                fontFamily: "Lato",
                fontSize: 16,
                color: selected ? MAIN_LIGHT_COLOR : MAIN_DARK_COLOR_LIGHTER,
                fontWeight: selected ? "bold" : "normal",
              }}
            >
              {it.name}
            </span>
          </button>
        );
      })}
    </div>
  );
};

export default CandleRangePicker;
