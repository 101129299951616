import { get } from "lodash";
import React, { useEffect } from "react";
import ReactMarkdown from "react-markdown";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  GET_COMPANY_REPORTS_START,
  RESET_COMPANY_REPORTS_PAGE_START,
  SET_COMPANY_REPORTS_PAGE_START,
  SET_COMPANY_REPORTS_PREVIOUS_PAGE_START,
} from "../actions/ReportsActions";
import { MAIN_DARK_COLOR, MAIN_DARK_COLOR_LIGHTER } from "../config/colors";
import { i18n } from "../config/i18n";
const CompanyReports = ({ symbol }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: RESET_COMPANY_REPORTS_PAGE_START,
    });
    dispatch({
      type: GET_COMPANY_REPORTS_START,
      payload: {
        company: symbol,
      },
    });
  }, []);

  const page = useSelector(({ ReportsReducer }) => ReportsReducer.page);

  const reports = useSelector(({ ReportsReducer }) => {
    return get(ReportsReducer, ["items", symbol]);
  });

  const hasNextPage = useSelector(({ ReportsReducer }) => {
    return get(ReportsReducer, ["hasNextPage"]);
  });

  return (
    <div>
      <h2>{i18n.COMPANY_REPORTS}</h2>
      {reports?.map((report, idx) => (
        <div
          style={{
            borderTopWidth: idx === 0 ? 0 : 1,
            borderTopStyle: "solid",
            borderTopColor: MAIN_DARK_COLOR_LIGHTER,
            paddingTop: 40,
            paddingBottom: 40,
          }}
          key={report.id}
        >
          <h2
            style={{ fontSize: 16, fontFamily: "Lato", color: MAIN_DARK_COLOR }}
          >
            {report?.dateTime} - {report?.title?.content}
          </h2>
          <ReactMarkdown style={{ text: { fontSize: 12 } }}>
            {report?.content?.content.split(" ").splice(0, 20).join(" ")}
          </ReactMarkdown>
          <Link to={`/espi/${report.id}`}>{i18n.MORE}</Link>
        </div>
      ))}
      {page > 1 && (
        <button
          style={{
            marginTop: "20px",
            marginBottom: "20px",
            border: "none",
            background: "none",
            color: MAIN_DARK_COLOR,
            fontWeight: "bold",
            fontSize: "1.2rem",
          }}
          onClick={() =>
            dispatch({
              type: SET_COMPANY_REPORTS_PREVIOUS_PAGE_START,
              payload: { company: symbol },
            })
          }
        >
          {i18n.PREVIOUS_PAGE}
        </button>
      )}
      {hasNextPage && (
        <button
          style={{
            marginTop: "20px",
            marginBottom: "20px",
            border: "none",
            background: "none",
            color: MAIN_DARK_COLOR,
            fontWeight: "bold",
            fontSize: "1.2rem",
          }}
          onClick={() =>
            dispatch({
              type: SET_COMPANY_REPORTS_PAGE_START,
              payload: { company: symbol },
            })
          }
        >
          {i18n.NEXT_PAGE}
        </button>
      )}
    </div>
  );
};
export default CompanyReports;
