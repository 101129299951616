import {
  MAIN_DARK_COLOR,
  MAIN_DARK_COLOR_LIGHTER,
  MAIN_LIGHT_COLOR,
} from "./colors";

export const buttonStyle = {
  borderRadius: 10,
  padding: 10,
  border: "none",
  backgroundColor: MAIN_DARK_COLOR,
  color: MAIN_LIGHT_COLOR,
};

export const inputStyle = {
  fontFamily: "Lato",
  fontSize: "18px",
  padding: 5,
  borderRadius: 5,
  borderWidth: 1,
  borderColor: MAIN_DARK_COLOR_LIGHTER,
  marginTop: 5,
  marginBottom: 5,
  flex: 1,
};

export const inputLabelStyle = {
  flex: 0.2,
  fontFamily: "Lato",
  fontSize: "18px",
  padding: 5,
  borderRadius: 5,
  borderWidth: 1,
  borderColor: MAIN_DARK_COLOR_LIGHTER,
  marginTop: 5,
  marginBottom: 5,
};
