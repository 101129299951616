import HttpService from "./HttpService";

export const getCompanyReports = ({
  market,
  company,
  page = 1,
  pageSize = 3,
  ids,
}) => {
  let url = `reports?Page=${page}&PageSize=${pageSize}&Market=${market}`;
  if (company) {
    url += `&StockSymbol=${company}`;
  }
  if (ids) {
    url += `&Ids=${ids}`;
  }
  return HttpService.get(url);
};

export const getReports = ({
  market,
  page = 1,
  pageSize = 20,
  Content,
  FromDateTime,
  ToDateTime,
}) => {
  let url = `reports?Page=${page}&PageSize=${pageSize}&Market=${market}`;
  if (Content && FromDateTime && ToDateTime) {
    url += `&Content=${Content}&FromDateTime=${FromDateTime}&ToDateTime=${ToDateTime}`;
  }
  return HttpService.get(url);
};

export const getReportById = (id) => {
  return HttpService.get(`reports/${id}`);
};
