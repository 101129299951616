export const GET_CANDLE_ANALYZER_SETTINGS_PROCESS =
  "GET_CANDLE_ANALYZER_SETTINGS_PROCESS";
export const GET_CANDLE_ANALYZER_SETTINGS_START =
  "GET_CANDLE_ANALYZER_SETTINGS_START";
export const GET_CANDLE_ANALYZER_SETTINGS_PROCESS_SUCCESS =
  "GET_CANDLE_ANALYZER_SETTINGS_PROCESS_SUCCESS";
export const GET_CANDLE_ANALYZER_SETTINGS_PROCESS_ERROR =
  "GET_CANDLE_ANALYZER_SETTINGS_PROCESS_ERROR";
export const GET_CANDLE_ANALYZER_SETTINGS_PROCESS_START =
  "GET_CANDLE_ANALYZER_SETTINGS_PROCESS_START";

export const SET_CANDLE_ANALYZER_SETTINGS_PROCESS =
  "SET_CANDLE_ANALYZER_SETTINGS_PROCESS";
export const SET_CANDLE_ANALYZER_SETTINGS_START =
  "SET_CANDLE_ANALYZER_SETTINGS_START";
export const SET_CANDLE_ANALYZER_SETTINGS_PROCESS_SUCCESS =
  "SET_CANDLE_ANALYZER_SETTINGS_PROCESS_SUCCESS";
export const SET_CANDLE_ANALYZER_SETTINGS_PROCESS_ERROR =
  "SET_CANDLE_ANALYZER_SETTINGS_PROCESS_ERROR";
export const SET_CANDLE_ANALYZER_SETTINGS_PROCESS_START =
  "SET_CANDLE_ANALYZER_SETTINGS_PROCESS_START";

export const GET_FORMATION_ANALYZER_SETTINGS_PROCESS =
  "GET_FORMATION_ANALYZER_SETTINGS_PROCESS";
export const GET_FORMATION_ANALYZER_SETTINGS_START =
  "GET_FORMATION_ANALYZER_SETTINGS_START";
export const GET_FORMATION_ANALYZER_SETTINGS_PROCESS_SUCCESS =
  "GET_FORMATION_ANALYZER_SETTINGS_PROCESS_SUCCESS";
export const GET_FORMATION_ANALYZER_SETTINGS_PROCESS_ERROR =
  "GET_FORMATION_ANALYZER_SETTINGS_PROCESS_ERROR";
export const GET_FORMATION_ANALYZER_SETTINGS_PROCESS_START =
  "GET_FORMATION_ANALYZER_SETTINGS_PROCESS_START";

export const SET_FORMATION_ANALYZER_SETTINGS_PROCESS =
  "SET_FORMATION_ANALYZER_SETTINGS_PROCESS";
export const SET_FORMATION_ANALYZER_SETTINGS_START =
  "SET_FORMATION_ANALYZER_SETTINGS_START";
export const SET_FORMATION_ANALYZER_SETTINGS_PROCESS_SUCCESS =
  "SET_FORMATION_ANALYZER_SETTINGS_PROCESS_SUCCESS";
export const SET_FORMATION_ANALYZER_SETTINGS_PROCESS_ERROR =
  "SET_FORMATION_ANALYZER_SETTINGS_PROCESS_ERROR";
export const SET_FORMATION_ANALYZER_SETTINGS_PROCESS_START =
  "SET_FORMATION_ANALYZER_SETTINGS_PROCESS_START";

export const GET_COMPANY_ALERTS_PROCESS = "GET_COMPANY_ALERTS_PROCESS";
export const GET_COMPANY_ALERTS_RESET = "GET_COMPANY_ALERTS_RESET";
export const GET_COMPANY_ALERTS_START = "GET_COMPANY_ALERTS_START";
export const GET_COMPANY_ALERTS_PROCESS_SUCCESS =
  "GET_COMPANY_ALERTS_PROCESS_SUCCESS";
export const GET_COMPANY_ALERTS_PROCESS_ERROR =
  "GET_COMPANY_ALERTS_PROCESS_ERROR";
export const GET_COMPANY_ALERTS_PROCESS_START =
  "GET_COMPANY_ALERTS_PROCESS_START";

export const GET_ALERTS_PROCESS = "GET_ALERTS_PROCESS";
export const GET_ALERTS_RESET = "GET_ALERTS_RESET";
export const GET_ALERTS_START = "GET_ALERTS_START";
export const GET_ALERTS_PROCESS_SUCCESS = "GET_ALERTS_PROCESS_SUCCESS";
export const GET_ALERTS_PROCESS_ERROR = "GET_ALERTS_PROCESS_ERROR";
export const GET_ALERTS_PROCESS_START = "GET_ALERTS_PROCESS_START";

export const SET_ALERTS_PAGE_PROCESS = "SET_ALERTS_PAGE_PROCESS";
export const SET_ALERTS_PAGE_START = "SET_ALERTS_PAGE_START";
export const SET_ALERTS_PAGE_PROCESS_SUCCESS =
  "SET_ALERTS_PAGE_PROCESS_SUCCESS";
export const SET_ALERTS_PAGE_PROCESS_ERROR = "SET_ALERTS_PAGE_PROCESS_ERROR";
export const SET_ALERTS_PAGE_PROCESS_START = "SET_ALERTS_PAGE_PROCESS_START";

export const RESET_ALERTS_PAGE_PROCESS = "RESET_ALERTS_PAGE_PROCESS";
export const RESET_ALERTS_PAGE_START = "RESET_ALERTS_PAGE_START";
export const RESET_ALERTS_PAGE_PROCESS_SUCCESS =
  "RESET_ALERTS_PAGE_PROCESS_SUCCESS";
export const RESET_ALERTS_PAGE_PROCESS_ERROR =
  "RESET_ALERTS_PAGE_PROCESS_ERROR";
export const RESET_ALERTS_PAGE_PROCESS_START =
  "RESET_ALERTS_PAGE_PROCESS_START";

export const RESET_COMPANY_ALERTS_PAGE_START =
  "RESET_COMPANY_ALERTS_PAGE_START";

export const SET_COMPANY_ALERTS_PAGE_START = "SET_COMPANY_ALERTS_PAGE_START";
export const SET_COMPANY_ALERTS_PREVIOUS_PAGE_START =
  "SET_COMPANY_ALERTS_PREVIOUS_PAGE_START";
