import React, { useEffect } from "react";
import { get } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  GET_COMPANY_ALERTS_START,
  SET_COMPANY_ALERTS_PAGE_START,
  SET_COMPANY_ALERTS_PREVIOUS_PAGE_START,
} from "../actions/AnalyzerAlertsActions";
import dayjs from "dayjs";
import { i18n } from "../config/i18n";
import { MAIN_DARK_COLOR_LIGHTER, MAIN_DARK_COLOR } from "../config/colors";

const CompanyAlerts = ({ symbol }) => {
  const dispatch = useDispatch();

  const alerts = useSelector(({ CompanyAlertsReducer }) =>
    get(CompanyAlertsReducer, ["items", symbol], [])
  );

  const hasNextPage = useSelector(({ CompanyAlertsReducer }) => {
    return get(CompanyAlertsReducer, ["hasNextPage"]);
  });

  const page = useSelector(
    ({ CompanyAlertsReducer }) => CompanyAlertsReducer.page
  );

  useEffect(() => {
    dispatch({
      type: GET_COMPANY_ALERTS_START,
      payload: symbol,
    });
  }, []);

  return (
    <div>
      <h1>{i18n.COMPANY_ALERTS}</h1>
      {alerts.map((alert) => {
        console.log(alert);
        // return alert.id;
        return (
          <div
            key={`alert_key_${alert.id}`}
            style={{
              borderBottomWidth: 1,
              borderBottomColor: MAIN_DARK_COLOR_LIGHTER,
              borderBottomStyle: "solid",
            }}
          >
            <h4
              style={{
                marginBottom: 0,
                paddingBottom: 0,
                fontFamily: "Lato",
                color: MAIN_DARK_COLOR,
              }}
            >
              {alert.triggerTaskType.analyzerName}
            </h4>
            <p
              style={{
                margin: 0,
                marginTop: 10,
                fontFamily: "Lato",
                color: MAIN_DARK_COLOR,
              }}
            >
              {dayjs(alert.candleReference.time.dateTime).format(
                i18n.CHART_DATE_FORMAT
              )}
            </p>
          </div>
        );
      })}

      <div>
        {page > 1 && (
          <button
            style={{
              marginTop: "20px",
              marginBottom: "20px",
              border: "none",
              background: "none",
              color: MAIN_DARK_COLOR,
              fontWeight: "bold",
              fontSize: "1.2rem",
            }}
            onClick={() =>
              dispatch({
                type: SET_COMPANY_ALERTS_PREVIOUS_PAGE_START,
                payload: symbol,
              })
            }
          >
            {i18n.PREVIOUS_PAGE}
          </button>
        )}
        {hasNextPage && (
          <button
            style={{
              marginTop: "20px",
              marginBottom: "20px",
              border: "none",
              background: "none",
              color: MAIN_DARK_COLOR,
              fontWeight: "bold",
              fontSize: "1.2rem",
            }}
            onClick={() =>
              dispatch({
                type: SET_COMPANY_ALERTS_PAGE_START,
                payload: symbol,
              })
            }
          >
            {i18n.NEXT_PAGE}
          </button>
        )}
      </div>
    </div>
  );
};

export default CompanyAlerts;
