export const REPORTS_GET_PROCESS_START = "REPORTS_GET_PROCESS_START";
export const REPORTS_GET_PROCESS_SUCCESS = "REPORTS_GET_PROCESS_SUCCESS";
export const REPORTS_GET_PROCESS_ERROR = "REPORTS_GET_PROCESS_ERROR";

export const REPORTS_PAGES_GET_PROCESS_START =
  "REPORTS_PAGES_GET_PROCESS_START";
export const REPORTS_PAGES_GET_PROCESS_SUCCESS =
  "REPORTS_PAGES_GET_PROCESS_SUCCESS";
export const REPORTS_PAGES_GET_PROCESS_ERROR =
  "REPORTS_PAGES_GET_PROCESS_ERROR";
export const REPORTS_PAGES_RESET = "REPORTS_PAGES_RESET";

export const REPORTS_ON_REFRESH = "REPORTS_ON_REFRESH";
export const REPORTS_RESET = "REPORTS_RESET";

export const GET_REPORT_BY_ID_PROCESS = "GET_REPORT_BY_ID_PROCESS";
export const GET_REPORT_BY_ID_START = "GET_REPORT_BY_ID_START";
export const GET_REPORT_BY_ID_PROCESS_START = "GET_REPORT_BY_ID_PROCESS_START";
export const GET_REPORT_BY_ID_PROCESS_SUCCESS =
  "GET_REPORT_BY_ID_PROCESS_SUCCESS";
export const GET_REPORT_BY_ID_PROCESS_ERROR = "GET_REPORT_BY_ID_PROCESS_ERROR";
export const GET_REPORT_BY_ID_RESET = "GET_REPORT_BY_ID_RESET";

// new actions

export const GET_REPORTS_PROCESS = "GET_REPORTS_PROCESS";

export const SET_REPORTS_PAGE_START = "SET_REPORTS_PAGE_START";
export const RESET_REPORTS_PAGE_START = "RESET_REPORTS_PAGE_START";

export const GET_REPORTS_START = "GET_REPORTS_START";
export const GET_REPORTS_PROCESS_START = "GET_REPORTS_PROCESS_START";
export const GET_REPORTS_PROCESS_SUCCESS = "GET_REPORTS_PROCESS_SUCCESS";
export const GET_REPORTS_PROCESS_ERROR = "GET_REPORTS_PROCESS_ERROR";

export const GET_COMPANY_REPORTS_PROCESS = "GET_COMPANY_REPORTS_PROCESS";
export const GET_COMPANY_REPORTS_START = "GET_COMPANY_REPORTS_START";
export const GET_COMPANY_REPORTS_PROCESS_START =
  "GET_COMPANY_REPORTS_PROCESS_START";
export const GET_COMPANY_REPORTS_PROCESS_SUCCESS =
  "GET_COMPANY_REPORTS_PROCESS_SUCCESS";
export const GET_COMPANY_REPORTS_PROCESS_ERROR =
  "GET_COMPANY_REPORTS_PROCESS_ERROR";

export const RESET_COMPANY_REPORTS_PAGE_START =
  "RESET_COMPANY_REPORTS_PAGE_START";
export const SET_COMPANY_REPORTS_PAGE_START = "SET_COMPANY_REPORTS_PAGE_START";
export const SET_COMPANY_REPORTS_PREVIOUS_PAGE_START =
  "SET_COMPANY_REPORTS_PREVIOUS_PAGE_START";

export const START_REPORTS_WEBSOCKET = "START_REPORTS_WEBSOCKET";
export const STOP_REPORTS_WEBSOCKET = "STOP_REPORTS_WEBSOCKET";

export const WEBSOCKET_OPENED = "WEBSOCKET_OPENED";
export const WEBSOCKET_RECEIVED_REPORT_START =
  "WEBSOCKET_RECEIVED_REPORT_START";
export const WEBSOCKET_RECEIVED_REPORT_SUCCESS =
  "WEBSOCKET_RECEIVED_REPORT_SUCCESS";

export const SET_REPORTS_SEARCH_DATA = "SET_REPORTS_SEARCH_DATA";
export const RESET_REPORTS_SEARCH_DATA = "RESET_REPORTS_SEARCH_DATA";
