import HttpService from './HttpService';

export const getCandle = ({ symbol, timeFrom, timeTo }) => {
  // hardcoded 15 for now for the page size.
  return HttpService.get('candles', {
    params: {
      Symbol: symbol,
      TimeFrom: timeFrom,
      TimeTo: timeTo,
      PageSize: 25,
    },
  });
};

export const getCandles = ({
  Page = 0,
  PageSize = 7,
  Market,
  Symbol,
  CandleType,
  TimeFrom,
  TimeTo,
}) => {
  return HttpService.get('candles', {
    params: {
      Page,
      PageSize,
      Market,
      Symbol,
      CandleType,
      TimeFrom,
      TimeTo,
    },
  });
};

export const getCandleChartOptions = () => {
  return HttpService.options('candles');
};

const CandleService = {
  getCandle,
};

export default CandleService;
