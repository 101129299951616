import { clone, find } from "lodash";
import {
  GET_ALL_ARTICLES_PROCESS_SUCCESS,
  GET_ARTICLE_BY_ID_PROCESS_SUCCESS,
  RESET_ALL_ARTICLES_PAGE_START,
  SET_ALL_ARTICLES_PAGE_START,
} from "../actions/ArticlesActions";

const ArticlesReducer = (
  state = { items: [], page: 1, hasNext: false },
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_ALL_ARTICLES_PROCESS_SUCCESS:
      const articles = payload.items;

      const newStateItems = clone(state.items);

      articles?.map((item) => {
        if (
          !find(newStateItems, (existingItem) => existingItem.id === item.id)
        ) {
          newStateItems.push(item);
        }
      });
      return {
        ...state,
        items: newStateItems,
        hasNext: payload.hasNext,
      };
    case GET_ARTICLE_BY_ID_PROCESS_SUCCESS:
      const article = payload;
      const newArticles = clone(state.items);

      if (
        !find(newArticles, (existingItem) => existingItem.id === article.id)
      ) {
        newArticles.push(article);
      }
      return {
        ...state,
        items: newArticles,
      };
    case RESET_ALL_ARTICLES_PAGE_START:
      return {
        ...state,
        items: [],
        page: 1,
      };

    case SET_ALL_ARTICLES_PAGE_START:
      return {
        ...state,
        page: state.page + 1,
      };

    default:
      return {
        ...state,
      };
  }
};

export default ArticlesReducer;
