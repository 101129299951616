import React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GET_CANDLES_RESET, GET_CANDLES_START } from "../actions/ChartActions";
import Chart from "react-apexcharts";
import dayjs from "dayjs";
import { i18n } from "../config/i18n";
import { useRef } from "react";
import useDimensions from "../hooks/useDimensionsHook";
import { find } from "lodash";

const prepareCandleData = (candles) => {
  const newCandles = [];
  candles?.forEach((candle) => {
    newCandles.push({
      x: dayjs(candle.identification.time.dateTime).format(
        i18n.CHART_DATE_FORMAT
      ),
      y: [
        candle.openClose.open,
        candle.minMax.max,
        candle.minMax.min,
        candle.openClose.close,
      ],
    });
  });

  return [{ data: newCandles.reverse() }];
};

const CandleChartView = ({ symbol, rangeItems, height }) => {
  const dispatch = useDispatch();
  const candles = useSelector(({ ChartReducer }) => ChartReducer.items);
  const now = new Date();
  const [seriesState, setSeries] = useState();
  const container = useRef();
  const containerWidth = useDimensions(container).width;

  useEffect(() => {
    dispatch({
      type: GET_CANDLES_RESET,
    });
  }, [dispatch]);

  useEffect(() => {
    const selectedRange = find(rangeItems, (it) => it.selected);
    if (!candles && selectedRange) {
      dispatch({
        type: GET_CANDLES_START,
        payload: {
          symbol,
          size: selectedRange.value,
          dateTo: new Date(),
          dateFrom: new Date(now.setDate(now.getDate() - selectedRange.value)),
          candleType: "Day",
        },
      });
    }
  }, [dispatch, candles, rangeItems]);

  useEffect(() => {
    setSeries(prepareCandleData(candles));
  }, [candles]);

  const [options, setOptions] = useState({
    // tooltip: {
    //   custom: function ({ series, seriesIndex, dataPointIndex, w }) {
    //     console.log("seriesState", seriesState);
    //     return (
    //       '<div class="arrow_box">' +
    //       "<span>" +
    //       series[seriesIndex][dataPointIndex] +
    //       "</span>" +
    //       "</div>"
    //     );
    //   },
    // },
    chart: {
      id: "basic-bar",
    },
  });

  return (
    <div
      ref={container}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {seriesState && (
        <Chart
          options={options}
          series={seriesState}
          type="candlestick"
          width={containerWidth}
          height={height}
        />
      )}
    </div>
  );
};
export default CandleChartView;
