import HttpService from "./HttpService";

export const getArticleCommentsById = ({ articleId, page = 1, pageSize = 5 }) =>
  HttpService.get(
    `/articles/${articleId}/comments?Page=${page}&PageSize=${pageSize}`
  );

export const putArticleComment = ({ articleId, content, login }) =>
  HttpService.put(`/articles/${articleId}/comments`, {
    content,
    owner: { login },
  });

export const deleteArticleComment = (articleId, commentId) =>
  HttpService.delete(`/articles/${articleId}/comments/${commentId}`);
