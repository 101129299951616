import { clone, find, get } from "lodash";
import {
  GET_REPORTS_PROCESS_SUCCESS,
  RESET_REPORTS_PAGE_START,
  RESET_REPORTS_SEARCH_DATA,
  SET_REPORTS_PAGE_START,
  SET_REPORTS_SEARCH_DATA,
  START_REPORTS_WEBSOCKET,
  STOP_REPORTS_WEBSOCKET,
  WEBSOCKET_RECEIVED_REPORT_SUCCESS,
} from "../actions/ReportsActions";

const AllReportsReducer = (
  state = {
    page: 1,
    items: [],
    hasNextPage: false,
    keyword: "",
    startDate: null,
    endDate: null,
  },
  action
) => {
  let newStateItems;
  switch (action.type) {
    case SET_REPORTS_SEARCH_DATA:
      const { keyword, startDate, endDate } = action.payload;
      return {
        ...state,
        keyword: keyword,
        startDate: startDate || state.startDate,
        endDate: endDate || state.endDate,
      };
    case RESET_REPORTS_SEARCH_DATA:
      return {
        ...state,
        keyword: "",
        startDate: undefined,
        endDate: undefined,
        page: 1,
        items: [],
      };
    case WEBSOCKET_RECEIVED_REPORT_SUCCESS:
      if (find(state.items, (item) => item.id === action.payload.id)) {
        return {
          ...state,
        };
      }
      newStateItems = [action.payload].concat(state.items);
      return { ...state, items: newStateItems };
    case GET_REPORTS_PROCESS_SUCCESS:
      const { items, hasNextPage } = action.payload;

      newStateItems = clone(state.items) || [];

      items?.map((item) => {
        if (
          !find(newStateItems, (existingItem) => existingItem.id === item.id)
        ) {
          newStateItems.push(item);
        }
      });

      return {
        ...state,
        items: newStateItems,
        hasNextPage,
      };

    case RESET_REPORTS_PAGE_START:
      return {
        ...state,
        page: 1,
        items: [],
      };
    case SET_REPORTS_PAGE_START:
      return {
        ...state,
        page: state.page + 1,
      };
    case START_REPORTS_WEBSOCKET:
      return {
        ...state,
        isWebsocketRunning: true,
      };
    case STOP_REPORTS_WEBSOCKET:
      return {
        ...state,
        isWebsocketRunning: false,
      };
    default:
      return {
        ...state,
      };
  }
};

export default AllReportsReducer;
