import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects';
import {
  GET_COMPANIES_PROCESS,
  GET_COMPANIES_PROCESS_SUCCESS,
  GET_COMPANIES_START,
  GET_TAB_COMPANIES_PROCESS,
  GET_TAB_COMPANIES_PROCESS_SUCCESS,
  GET_TAB_COMPANIES_START,
  SET_TAB_COMPANIES_FILTER_PROCESS,
  SET_TAB_COMPANIES_FILTER_PROCESS_SUCCESS,
  SET_TAB_COMPANIES_FILTER_START,
} from '../actions/CompanyActions';
import { MARKET } from '../config/constants';
import { getCompanies } from '../services/CompaniesService';
import { filter } from 'lodash';
import genericSagaHandler from './CommonSaga';

function* doGetCompanies() {
  yield genericSagaHandler(GET_COMPANIES_PROCESS, function* () {
    const { data } = yield getCompanies({ market: MARKET });
    const { items } = data;

    yield put({
      type: GET_COMPANIES_PROCESS_SUCCESS,
      payload: items,
    });
  });
}

function* doGetTabCompanies() {
  yield genericSagaHandler(GET_TAB_COMPANIES_PROCESS, function* () {
    const filterName = yield select(
      ({ CompaniesTabReducer }) =>
        CompaniesTabReducer?.filter?.toLowerCase() || ''
    );
    const { data } = yield getCompanies({ market: MARKET });
    const { items } = data;
    const filteredCompanies = filter(items, (item) => {
      return (
        item.name.name.toLowerCase().indexOf(filterName) !== -1 ||
        item.symbol.abbreviation.toLowerCase().indexOf(filterName) !== -1 ||
        filterName === ''
      );
    });
    yield put({
      type: GET_TAB_COMPANIES_PROCESS_SUCCESS,
      payload: { items: filteredCompanies },
    });
  });
}

function* doSetTabFilter({ payload }) {
  yield genericSagaHandler(SET_TAB_COMPANIES_FILTER_PROCESS, function* () {
    const filterName = payload?.filter || '';
    yield put({
      type: SET_TAB_COMPANIES_FILTER_PROCESS_SUCCESS,
      payload: filterName,
    });
  });
}

function* getCompanySaga() {
  yield takeEvery(GET_TAB_COMPANIES_START, doGetTabCompanies);
  yield takeEvery(SET_TAB_COMPANIES_FILTER_START, doSetTabFilter);
  yield takeLeading(GET_COMPANIES_START, doGetCompanies);
}

export default function* CompanySagas() {
  yield all([getCompanySaga()]);
}
