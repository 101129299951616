import { clone, findIndex } from 'lodash';
import {
  RESET_CHAT_MESSAGES_START,
  WEBSOCKET_RECEIVED_CHAT_MESSAGE_PROCESS_SUCCESS,
} from '../actions/ChatActions';

const DEFAULT_STATE = { messages: [] };

const ChatReducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    // case POST_CHAT_MESSAGE_PROCESS_SUCCESS:
    case WEBSOCKET_RECEIVED_CHAT_MESSAGE_PROCESS_SUCCESS:
      let messages = clone(state.messages);
      // let originalMessages = clone(state.messages);
      let incomingMessages = action.payload;
      console.log('incoming', incomingMessages);
      incomingMessages.forEach((message) => {
        const existingIndex = findIndex(messages, (m) => m.id === message.id);
        if (existingIndex === -1) {
          messages.unshift(message);
        }
      });

      // messages = messages.concat(originalMessages);
      return {
        ...state,
        messages,
      };
    case RESET_CHAT_MESSAGES_START:
      return {
        ...state,
        messages: [],
      };
    default:
      return state;
  }
};
export default ChatReducer;
