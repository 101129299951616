import React from "react";
import BasePage from "../components/BasePage";
import patroniteLogo from "../img/patronite-logos-1.png";
import fbLogo from "../img/fb-logo.png";
import ttLogo from "../img/twitter.png";

const AboutRoute = () => {
  return (
    <BasePage>
      <h1>Ważne informacje!</h1>
      <p>
        Witamy na nowej stronie portalu !
      </p>
      <p>
      Strona ta jest pierwszym krokiem do odświeżenia starej wersji strony 
      która wciąż jest dostępna pod adresem <a href="https://www.stockup.cloud" target="_blank" rel="noopener noreferrer">www.stockup.cloud</a>.
      Zapraszamy jak zawsze do ściągnięcia aplikacji mobilnej - linki w stopce na stronie.
      </p>
      <p>
      Tymczasowy status subskrypcji premium+ trającej do 30.6.22 jest nadany po rejestracji w okresie (20.6.22 - 30.6.22). Niektóre
      funkcjonalności dostępne jedynie po rejestracji lub w przypadku posiadania subskrypcji (premium oraz
      spersonalizowane). Więcej informacji na temat subskrypcji pod adresem: <a href="https://www.stockup.cloud/subscription-descriptions" target="_blank" rel="noopener noreferrer">www.stockup.cloud/subscription-descriptions.</a>
      </p>
        
  

      <div style={{ display: "flex", flexDirection: "column" }}>
        <p>
          Serdecznie zapraszamy do wsparcia nas poprzez{" "}
          <a href="https://patronite.pl/Stockup" target="_blank" rel="noopener noreferrer">Patronite.</a>
        </p>
        <a href="https://patronite.pl/Stockup" target="_blank" rel="noopener noreferrer">
          <img src={patroniteLogo} style={{ maxWidth: "100%" }} />
        </a>
      </div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <p>Zaprszamy na nasze Social Media</p>
        <p> <a href="https://twitter.com/StockupG" target="_blank" rel="noopener noreferrer">
            <img src={ttLogo} style={{ maxWidth: "100%" }} />
          </a>
               
          <a
            href="https://www.facebook.com/StockUp-GPW-103150991463506/"
            target="_blank" rel="noopener noreferrer"          >
            <img src={fbLogo} style={{ maxWidth: "100%" }} />
          </a>
          </p>
       


        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            margin: 20,
            
          }}
        >
         

          
        </div>
        <p>
          W celu kontaktu prosimy wysłać maila pod adres{" "}
          <a href="mailto: kontakt@stockup.pl">kontakt@stockup.pl</a> lub
          skontaktować się przez media społecznościowe
        </p>
        <p>
          Jako że serwis jest w początkowej fazie budowy wciąż mogą się pojawiać
          drobne błędy o których zgłoszenie uprzejmnie prosimy.
        </p>
      </div>
    </BasePage>
  );
};

export default AboutRoute;
