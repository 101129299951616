import {
  GET_CANDLES_PROCESS_SUCCESS,
  GET_CANDLES_RESET,
} from '../actions/ChartActions';

const ChartReducer = (state = {}, action) => {
  const { payload, type } = action;
  switch (type) {
    case GET_CANDLES_PROCESS_SUCCESS:
      return {
        ...state,
        items: payload,
      };
    case GET_CANDLES_RESET:
      return {
        ...state,
        items: null,
      };
    default:
      return state;
  }
};
export default ChartReducer;
