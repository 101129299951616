import React, { useEffect, useState } from "react";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { i18n } from "../config/i18n";

const MobileMenu = () => {
  const { MAIN_LIGHT_COLOR, MAIN_DARK_COLOR_LIGHTER, MAIN_DARK_COLOR } =
    useSelector(({ ConfigReducer }) => ConfigReducer.colors);

  const [showMenu, setShowMenu] = useState(false);

  const token = useSelector(({ LoginReducer }) => LoginReducer.token);

  return (
    <div
      style={{ display: "flex", flex: 1, justifyContent: "flex-end" }}
      className={"hide-on-desktop"}
    >
      <button
        onClick={() => setShowMenu(!showMenu)}
        style={{
          background: "none",
          alignItems: "center",
          justifyContent: "center",
          width: 35,
          height: 35,
          // borderColor: MAIN_DARK_COLOR_LIGHTER,
          // borderRadius: 10,
          // borderWidth: 1,
          border: "none",
        }}
      >
        <FontAwesomeIcon
          icon={faBars}
          color={MAIN_DARK_COLOR_LIGHTER}
          size="2x"
        />
      </button>

      {
        <div
          className={showMenu ? "slide-in" : "slide-out"}
          style={{
            height: "100%",
            width: "100%",
            left: 0,
            top: 0,
            backgroundColor: MAIN_LIGHT_COLOR,
            borderColor: MAIN_DARK_COLOR_LIGHTER,
            zIndex: 100,
            position: "absolute",
            borderWidth: 1,
          }}
        >
          <button
            onClick={() => setShowMenu(!showMenu)}
            style={{
              position: "absolute",
              top: 10,
              right: 10,
              background: "none",
              alignItems: "flex-end",
              justifyContent: "flex-end",
              width: 35,
              height: 35,
              border: "none",
            }}
          >
            <FontAwesomeIcon
              icon={faTimes}
              color={MAIN_DARK_COLOR_LIGHTER}
              size={"2x"}
            />
          </button>
          <ul className="mobileUl">
            <li style={{ flex: 0.2 }}>
              <Link
                to="/"
                style={{
                  color: MAIN_DARK_COLOR,
                  fontFamily: "Lato",
                  fontSize: 24,
                  textDecoration: "none",
                }}
              >
                {i18n.HOME}
              </Link>
            </li>

            <li style={{ flex: 0.2 }}>
              <Link
                to="/companies"
                style={{
                  color: MAIN_DARK_COLOR,
                  fontFamily: "Lato",
                  fontSize: 24,
                  textDecoration: "none",
                }}
              >
                {i18n.COMPANIES}
              </Link>
            </li>
            <li style={{ flex: 0.2 }}>
              <Link
                to="/espi"
                style={{
                  color: MAIN_DARK_COLOR,
                  fontFamily: "Lato",
                  fontSize: 24,
                  textDecoration: "none",
                }}
              >
                {i18n.ESPI}
              </Link>
            </li>
            <li style={{ flex: 0.2 }}>
              <Link
                to="/about"
                style={{
                  color: MAIN_DARK_COLOR,
                  fontFamily: "Lato",
                  fontSize: 24,
                  textDecoration: "none",
                }}
              >
                {i18n.ABOUT}
              </Link>
            </li>
            <li style={{ flex: 0.2 }}>
              <Link
                to="/terms"
                style={{
                  color: MAIN_DARK_COLOR,
                  fontFamily: "Lato",
                  fontSize: 24,
                  textDecoration: "none",
                }}
              >
                {i18n.TERMS}
              </Link>
            </li>
            {!token && (
              <li style={{ flex: 0.2 }}>
                <Link
                  to="/login"
                  style={{
                    color: MAIN_DARK_COLOR,
                    fontFamily: "Lato",
                    fontSize: 24,
                    textDecoration: "none",
                  }}
                >
                  {i18n.LOGIN}
                </Link>
              </li>
            )}
          </ul>
        </div>
      }
    </div>
  );
};

export default MobileMenu;
