import { find } from "lodash";
import React, { useEffect } from "react";
import ReactMarkdown from "react-markdown";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { GET_ARTICLE_BY_ID_START } from "../actions/ArticlesActions";
import BasePage from "../components/BasePage";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";

import {
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
} from "react-share";
import MobileConfig from "../config/Config";
import CommentsList from "../components/CommentsList";
import AddCommentForm from "../components/AddCommentForm";

const ArticleRoute = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: GET_ARTICLE_BY_ID_START, payload: { id } });
  }, []);

  const article = useSelector(({ ArticlesReducer }) =>
    find(ArticlesReducer.items, (item) => item.id === id)
  );

  return (
    <BasePage>
      {article && (
        <>
          <h1 className="markdown">{article.title}</h1>
          <div style={{ display: "flex", flex: 1, justifyContent: "flex-end" }}>
            <EmailShareButton
              url={`${MobileConfig.BASE_PORTAL_URL}article/${id}`}
            >
              <EmailIcon size={32} round style={{ marginRight: 5 }} />
            </EmailShareButton>
            <FacebookShareButton
              url={`${MobileConfig.BASE_PORTAL_URL}article/${id}`}
            >
              <FacebookIcon size={32} round={true} style={{ marginRight: 5 }} />
            </FacebookShareButton>
            <TwitterShareButton
              url={`${MobileConfig.BASE_PORTAL_URL}article/${id}`}
            >
              <TwitterIcon size={32} round={true} style={{ marginRight: 5 }} />
            </TwitterShareButton>
            <LinkedinShareButton
              url={`${MobileConfig.BASE_PORTAL_URL}article/${id}`}
            >
              <LinkedinIcon size={32} round={true} style={{ marginRight: 5 }} />
            </LinkedinShareButton>
          </div>
          <ReactMarkdown className="markdown">{article.content}</ReactMarkdown>
          {article.isCommentAllowed && (
            <>
              <AddCommentForm articleId={id} />
              <CommentsList articleId={id} />
            </>
          )}
        </>
      )}
    </BasePage>
  );
};

export default ArticleRoute;
