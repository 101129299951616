// import { createAction } from "redux-actions";

export const POST_LOGIN_PROCESS = "POST_LOGIN_PROCESS";
export const POST_LOGIN_START = "POST_LOGIN_START";
export const POST_LOGIN_PROCESS_SUCCESS = "POST_LOGIN_PROCESS_SUCCESS";
export const POST_LOGIN_PROCESS_ERROR = "POST_LOGIN_PROCESS_ERROR";
export const POST_LOGIN_PROCESS_START = "POST_LOGIN_PROCESS_START";

export const POST_REFRESH_TOKEN_PROCESS = "POST_REFRESH_TOKEN_PROCESS";
export const POST_REFRESH_TOKEN_START = "POST_REFRESH_TOKEN_START";
export const POST_REFRESH_TOKEN_PROCESS_SUCCESS =
  "POST_REFRESH_TOKEN_PROCESS_SUCCESS";
export const POST_REFRESH_TOKEN_PROCESS_ERROR =
  "POST_REFRESH_TOKEN_PROCESS_ERROR";
export const POST_REFRESH_TOKEN_PROCESS_START =
  "POST_REFRESH_TOKEN_PROCESS_START";

export const LOGOUT_PROCESS = "LOGOUT_PROCESS";
export const LOGOUT_START = "LOGOUT_START";
export const LOGOUT_PROCESS_SUCCESS = "LOGOUT_PROCESS_SUCCESS";
export const LOGOUT_PROCESS_ERROR = "LOGOUT_PROCESS_ERROR";
export const LOGOUT_PROCESS_START = "LOGOUT_PROCESS_START";

// OLD ACTIONS
export const LOGIN_REQUEST_PROCESS = "LOGIN_REQUEST_PROCESS";

export const LOGIN_REQUEST_PROCESS_START = "LOGIN_REQUEST_PROCESS_START";
export const LOGIN_REQUEST_PROCESS_SUCCESS = "LOGIN_REQUEST_PROCESS_SUCCESS";
export const LOGIN_REQUEST_ERROR = "LOGIN_REQUEST_ERROR";
export const LOGIN_REQUEST_PROCESS_ERROR = "LOGIN_REQUEST_PROCESS_ERROR";
export const LOGIN_REQUEST_PROCESS_RESET = "LOGIN_REQUEST_PROCESS_RESET";
export const LOGIN_RETRIEVE_TOKEN_REQUEST = "LOGIN_RETRIEVE_TOKEN_REQUEST";
export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_REQUEST_COMPLETE = "LOGOUT_REQUEST_COMPLETE";

export const GET_NICK_PROCESS = "GET_NICK_PROCESS";
export const GET_NICK_START = "GET_NICK_START";
export const GET_NICK_PROCESS_START = "GET_NICK_PROCESS_START";
export const GET_NICK_PROCESS_SUCCESS = "GET_NICK_PROCESS_SUCCESS";
export const GET_NICK_PROCESS_ERROR = "GET_NICK_PROCESS_ERROR";

// export const loginRetrieve = createAction(LOGIN_RETRIEVE_TOKEN_REQUEST);
// export const logoutRequest = createAction(LOGOUT_REQUEST);

// const loginRequestAction = createAction(LOGIN_REQUEST_PROCESS_START);

// export const loginRequest = ({ email, password }) => {
//   console.log(email, password);
//   return loginRequestAction({
//     email,
//     password,
//   });
// };
