export const companiesWebsite = {
  "11B": "http://www.11bitstudios.pl",
  "3RG": "http://https://3r.games",
  "4FM": "http://www.4funmedia.pl",
  IRL: "http://www.interrao.lt",
  NTU: "http://www.novaturas.lt",
  ABE: "http://www.ab.pl",
  ACG: "http://www.ac.com.pl",
  ACT: "http://www.action.pl",
  ADV: "http://www.adiuvo.pl",
  AGO: "http://www.agora.pl",
  AGT: "http://www.agroton.com.ua",
  ALL: "http://www.ailleron.com",
  AWM: "http://www.airwaymedix.pl",
  ALR: "http://www.aliorbank.pl",
  ALG: "http://www.allingames.com",
  ALE: "http://www.allegro.eu",
  AAT: "http://www.altasa.pl",
  ALI: "http://altustfi.pl",
  AML: "http://www.alumetal.pl",
  AMB: "http://www.ambra.com.pl",
  AMC: "http://www.amica.com.pl",
  APL: "http://www.ampli.com.pl",
  EAT: "http://www.amrest.eu",
  ANR: "http://www.answear.com",
  APT: "http://www.apator.com",
  APN: "http://www.aplisens.pl",
  APE: "http://www.apsenergia.pl",
  ARH: "http://www.archicom.pl",
  ATC: "http://www.arcticpaper.com",
  ARR: "http://www.arteria.pl",
  ART: "http://www.artifexmundi.com",
  SFG: "http://www.silvanofashion.com",
  ASB: "http://www.asbis.com",
  ASM: "http://www.asmgroup.pl",
  ABS: "http://www.assecobs.pl",
  ACP: "http://www.asseco.pl",
  ASE: "http://www.asseco-see.com",
  AST: "http://www.astartakiev.com",
  "1AT": "http://www.atal.pl",
  ATD: "http://www.atende.pl",
  ATP: "http://www.atlantapoland.com.pl",
  ATS: "http://www.atlantis-sa.pl",
  ATL: "http://www.atlasestates.com",
  ATG: "http://www.atmgrupa.pl",
  ATR: "http://www.atrem.pl",
  AUG: "http://www.auga.lt",
  APR: "http://www.auto-partner.pl",
  WIS: "http://www.balticbridge.com.pl",
  SAN: "http://www.santander.com",
  BHW: "http://www.citihandlowy.pl",
  MIL: "http://www.bankmillennium.pl",
  BOS: "http://www.bosbank.pl",
  PEO: "http://www.pekao.com.pl",
  BBD: "http://www.bbidevelopment.pl",
  BFT: "http://www.benefitsystems.pl",
  BRG: "http://www.berling.pl",
  BST: "http://www.best.com.pl",
  BCM: "http://www.betacom.com.pl",
  BML: "http://www.biomed.lublin.pl",
  BIO: "http://www.bioton.pl",
  BIK: "http://www.bik.com.pl",
  BNP: "http://www.bnpparibas.pl",
  BBT: "http://www.boombit.com",
  BRS: "http://www.boryszew.com.pl",
  BOW: "http://www.bowim.pl",
  B24: "http://www.brand24.pl",
  BRA: "http://www.braster.eu",
  BAH: "http://www.ba-holding.pl",
  BDX: "http://www.budimex.pl",
  BMC: "http://www.bumech.pl",
  CPG: "http://www.capitalpark.pl",
  CPA: "http://www.c-p.pl",
  CTX: "http://www.captortherapeutics.com",
  CCC: "http://www.ccc.eu",
  CDR: "http://www.cdprojekt.pl",
  CDL: "http://www.cdrl.pl",
  CLN: "http://www.celonpharma.com",
  ENE: "http://www.enel.pl",
  CNT: "http://www.cntsa.pl",
  CEZ: "http://www.cez.cz",
  CFI: "http://www.cfiholding.pl",
  CIG: "http://www.cigames.com",
  CIE: "http://www.ciechgroup.com",
  CTS: "http://www.cityservice.eu",
  CCE: "http://www.ccenergy.pl",
  CLE: "http://www.coalenergy.com.ua",
  COG: "http://www.cognor.pl",
  CMR: "http://www.comarch.pl",
  CMP: "http://www.comp.com.pl",
  CPL: "http://www.comperia.pl",
  CPD: "http://www.cpdsa.pl",
  OPG: "http://www.orcogroup.com",
  CRJ: "http://www.creepyjar.com",
  CPS: "http://www.grupapolsat.pl",
  CZT: "http://www.czerwonatorebka.pl",
  DAD: "http://www.dadelo.pl",
  DAT: "http://www.datawalk.pl",
  DCR: "http://www.decora.pl",
  DEK: "http://www.dekpol.pl",
  DEL: "http://www.delko.com.pl",
  DVL: "http://www.develia.pl",
  DGA: "http://www.dga.pl",
  DTR: "http://www.digitree.pl",
  DNP: "http://www.grupadino.pl",
  DOM: "http://www.domd.pl",
  DRE: "http://.",
  DPL: "http://www.drozapol.pl",
  ECH: "http://www.echo.com.pl",
  EDI: "http://www.edinvest.pl",
  EEX: "http://www.ekoexport.pl",
  BDZ: "http://www.ecbedzin.pl",
  ELT: "http://www.elektrotim.pl",
  EKP: "http://www.elkop.pl",
  EMC: "http://www.emc-sa.pl",
  ENA: "http://www.enea.pl",
  EST: "http://www.e-star.hu",
  ENG: "http://www.grupa.energa.pl",
  ENP: "http://www.enap.com.pl",
  ENI: "http://www.energoinstal.pl",
  ENT: "http://www.enterair.pl",
  ERB: "http://www.erbud.pl",
  ERG: "http://www.erg.com.pl",
  EAH: "http://www.esotiqhenderson.com",
  EUR: "http://www.grupaeurocash.pl",
  EHG: "http://www.eurohold.bg",
  EUC: "http://www.euco.pl",
  ETL: "http://www.eurotel.pl",
  SKA: "http://www.sniezka-sa.pl",
  RAF: "http://www.rafamet.com.pl",
  FTE: "http://www.forte.com.pl/pl",
  FSG: "http://www.fasing.pl",
  FMF: "http://www.famur.com",
  FFI: "http://www.fastfinance.pl",
  FEE: "http://www.feerum.pl",
  FRO: "http://www.ferro.pl",
  FER: "http://www.ferrum.com.pl",
  DBC: "http://www.debica.com.pl",
  FON: "http://www.fon-sa.pl",
  GOP: "http://www.gameops.pl",
  GIF: "http://",
  GBK: "http://www.getbacksa.pl",
  GTN: "http://www.getin.pl",
  GNB: "http://www.gnb.pl",
  GPW: "http://www.gpw.pl",
  VIN: "http://www.gpm-vindexus.pl",
  GLC: "http://www.globalcosmed.pl",
  GTC: "http://www.gtc.com.pl",
  GOB: "http://www.gobarto.pl",
  GCN: "http://www.groclin.com.pl",
  GRN: "http://www.grodno.pl",
  ATT: "http://www.grupaazoty.com",
  ZAP: "http://pulawy.grupaazoty.com",
  PCE: "http://www.zchpolice.grupaazoty.com",
  GKI: "http://www.immobile.com.pl",
  KTY: "http://www.grupakety.com",
  LTS: "http://www.lotos.pl",
  ZWC: "http://www.grupazywiec.pl",
  HRP: "http://www.harperhygienics.pl",
  HEL: "http://www.helio.pl",
  HRS: "http://www.herkules-polska.pl",
  HMI: "http://www.grupainwest.pl",
  HLD: "http://www.hollywoodsa.eu",
  HUGE: "http://www.huuugegames.com",
  I2D: "http://www.i2development.pl",
  IDM: "http://www.idmsa.pl",
  IFI: "http://www.power.com.pl",
  IMC: "http://www.imcagro.com.ua",
  IIA: "http://www.immofinanz.com",
  IMP: "http://www.imperasa.pl",
  IMS: "http://www.imssensory.com",
  INC: "http://www.incsa.pl",
  IDG: "http://www.indygotechminerals.com",
  ING: "http://www.ingbank.pl",
  INP: "http://www.inpro.com.pl",
  INK: "http://www.instalkrakow.pl",
  CAR: "http://www.intercars.com.pl",
  ITB: "http://www.interbud.com.pl",
  INF: "http://www.interferie.pl",
  ITM: "http://https://intermatrade.pl",
  IPF: "http://www.ipfin.co.uk",
  IPO: "http://www.intersport.pl",
  INL: "http://www.introlsa.pl",
  IFC: "http://www.ifcapital.pl",
  IFR: "http://www.ifsa.pl",
  INV: "http://www.invista.com.pl",
  IPE: "http://www.ipopema.pl",
  IZB: "http://www.izoblok.pl",
  IZO: "http://www.izolacja-jarocin.pl",
  IZS: "http://www.izostal.com.pl",
  JWC: "http://www.jwconstruction.com.pl",
  JSW: "http://www.jsw.pl",
  JWW: "http://www.jwwinvest.pl",
  K2H: "http://www.k2.pl",
  KCI: "http://www.kci.pl",
  KDM: "http://www.kdmshipping.com",
  KER: "http://www.kernel.ua",
  KGH: "http://www.kghm.com",
  KPL: "http://www.relacjeinwestorskie.kinopolska.pl",
  KOM: "http://www.komputronik.pl",
  KBD: "http://www.kbdom.eu",
  EFK: "http://www.efektsa.pl",
  KGL: "http://www.kgl.pl",
  KPD: "http://www.kppd.pl",
  KCH: "http://www.krakchemia.pl",
  KRI: "http://www.kredytinkaso.pl",
  KRK: "http://www.krka.biz",
  KRU: "http://www.kruksa.pl",
  KVT: "http://www.krynicavitamin.com",
  KRC: "http://www.krynicki.pl",
  KSG: "http://www.ksgagro.com",
  LAB: "http://www.laboprint.eu",
  LRK: "http://www.mitsa.pl",
  LRQ: "http://www.larq.pl",
  LEN: "http://www.lenalighting.pl",
  LTX: "http://www.lentex.com.pl",
  LBT: "http://www.libet.pl",
  LVC: "http://www.livechatsoftware.pl",
  LKD: "http://www.lokum-deweloper.pl",
  LPP: "http://www.lppsa.com",
  LSI: "http://www.lsisoftware.pl",
  LBW: "http://www.lubawa.com.pl",
  LWB: "http://www.lw.com.pl",
  MWT: "http://www.mwtrade.pl",
  MAB: "http://www.mabion.eu",
  "06N": "http://www.magnapolonia.com.pl",
  MAK: "http://www.makarony.pl",
  MGT: "http://www.mangata.com.pl",
  MBW: "http://www.mbws.com",
  MVP: "http://http://relacje.marvipoldevelopment.pl/",
  MPH: "http://www.masterpharm.pl",
  MXC: "http://www.maxcom.pl",
  MBK: "http://www.mbank.pl",
  MCI: "http://www.mci.pl",
  MDI: "http://www.mdienergia.pl",
  MCP: "http://www.mediacap.pl",
  MDG: "http://www.medicalgorithmics.pl",
  ICE: "http://www.medinice.pl",
  MEG: "http://www.megaron.com.pl",
  MNC: "http://www.mennica.com.pl",
  MRC: "http://www.mercatormedical.eu",
  MCR: "http://www.mercor.com.pl",
  MEX: "http://www.mexpolska.pl",
  MFO: "http://www.mfo.pl",
  MLK: "http://www.milkiland.nl",
  MIR: "http://www.miraculum.pl",
  MRB: "http://www.mirbud.pl",
  MLS: "http://www.mlsystem.pl",
  MLG: "http://www.mlp.pl",
  MBR: "http://www.mobruk.pl",
  MOJ: "http://www.moj.com.pl",
  MOL: "http://www.mol.hu",
  MON: "http://www.monnari.com.pl",
  MSP: "http://www.mostostal-plock.pl",
  MSW: "http://www.mostostal.waw.pl",
  MSZ: "http://www.mz.pl",
  MZA: "http://www.muza.com.pl",
  NNG: "http://www.nanogroup.eu",
  NET: "http://www.netia.pl",
  NEU: "http://www.neuca.pl",
  NWG: "http://www.newag.pl",
  NXG: "http://www.nexity.io",
  IBS: "http://www.ibsmsa.pl",
  NVG: "http://http://rpsa.com.pl",
  NVT: "http://www.novita.com.pl",
  NTT: "http://www.ntt.com.pl",
  "08N": "http://www.octava.com.pl",
  ODL: "http://www.odlewniepolskie.pl",
  OEX: "http://www.oex.pl",
  OAT: "http://www.oncoarendi.com",
  FMG: "http://www.psgsa.pl",
  OPF: "http://www.open.pl",
  OPN: "http://www.oponeo.pl",
  OPM: "http://www.opteam.pl",
  OPL: "http://www.orange.pl",
  OBL: "http://www.orzel-bialy.com.pl",
  OTS: "http://www.otlogistics.pl",
  OVO: "http://www.ovostar.ua",
  NVA: "http://www.pa-nova.com.pl",
  PMP: "http://www.pamapol.com.pl",
  PAT: "http://www.patentus.pl",
  PBG: "http://www.pbg-sa.pl",
  PBF: "http://www.pbs-finanse.pl",
  PCG: "http://www.pcguard.pl",
  PCX: "http://www.pcc-exol.pl",
  PCR: "http://www.pcc.rokita.pl",
  PCF: "http://www.peoplecanfly.com",
  PCO: "http://www.pepcogroup.eu",
  PGE: "http://www.gkpge.pl",
  PGV: "http://www.zastal.pl",
  PSW: "http://www.pgs-soft.com",
  PHR: "http://www.pharmena.com.pl",
  PEN: "http://www.photonenergy.com",
  PJP: "http://www.pjpmakrum.com",
  PKP: "http://www.pkp-cargo.eu",
  PIW: "http://http://platynoweinwestycje.pl/",
  PLW: "http://www.playway.com",
  PLZ: "http://www.plazacenters.com",
  PGM: "http://www.pmpg.pl",
  PEP: "http://www.polenergia.pl",
  PXM: "http://www.polimex-mostostal.pl",
  PND: "http://www.polnord.pl",
  BKM: "http://www.pbkm.pl",
  PHN: "http://www.phnsa.pl",
  PKN: "http://www.orlen.pl",
  PGN: "http://www.pgnig.pl",
  PWX: "http://www.polwax.pl",
  PKO: "http://www.pkobp.pl",
  PZU: "http://www.pzu.pl",
  POZ: "http://www.pozbud.pl",
  PBX: "http://www.pekabex.pl",
  PRI: "http://www.pragma.pl",
  PRF: "http://www.pragmago.pl",
  PDZ: "http://www.pdz.com.au",
  PMA: "http://www.primamoda.com.pl",
  PTH: "http://www.primetechsa.pl",
  PRD: "http://www.procad.pl",
  PRM: "http://www.prochem.com.pl",
  PRT: "http://www.protektorsa.pl",
  HDR: "http://www.hydrotor.com.pl",
  KMP: "http://www.kompap.pl",
  PPS: "http://www.pepees.pl",
  PLX: "http://www.plast-box.com",
  PUN: "http://www.punkpirates.io",
  PUR: "http://www.purebiologics.com",
  CRM: "http://www.cormay.pl",
  QNT: "http://www.quantum-software.com",
  QRS: "http://www.quercustfi.pl",
  R22: "http://www.r22.pl",
  RDL: "http://www.radpol.com.pl",
  RFK: "http://www.rafako.com.pl",
  RBW: "http://http://r.pl/",
  RNK: "http://www.rankprogress.pl",
  RWL: "http://www.rawlplug.com",
  RDN: "http://www.redan.com.pl",
  REG: "http://www.regnon.com",
  RHD: "http://www.reinhold-europe.se/en/home",
  RNC: "http://www.reinocapital.pl",
  RLP: "http://www.relpol.com.pl",
  RMK: "http://www.remak.com.pl",
  RES: "http://www.resbud.pl",
  RON: "http://www.ronson.pl",
  RVU: "http://www.ryvu.com",
  SGR: "http://www.sadovayagroup.com",
  SNK: "http://www.sanokrubber.pl",
  SPL: "http://www.santander.pl",
  SNW: "http://www.holding.sanwil.com",
  STS: "http://http://satisgroupsa.pl/",
  SWG: "http://www.secowarwick.com.pl",
  SEK: "http://www.rybki-seko.pl",
  SEL: "http://www.selena.com",
  SLV: "http://www.selvita.com",
  SEN: "http://www.serinusenergy.com",
  SES: "http://www.sescom.eu",
  SFS: "http://www.sfinks.pl",
  SVRS: "https://www.gpw.pl/pub/GPW/files/PDF/REG_S/Zalacznik_14_czesc_A.docx\n",
  SME: "http://www.simple.com.pl",
  SKH: "http://www.skarbiecholding.pl",
  SKT: "http://www.skotansa.pl",
  SKL: "http://www.skyline.com.pl",
  SLZ: "http://www.sleepz.com",
  SHD: "http://www.blacklion.com.pl",
  SOL: "http://www.solar.com.pl",
  SON: "http://www.sonel.pl",
  SPH: "http://www.sopharmagroup.com",
  STX: "http://www.stalexport-autostrady.pl",
  STP: "http://www.stalprodukt.pl",
  STF: "http://www.stalprofil.com.pl",
  SHG: "http://www.starhedge.pl",
  SNX: "http://www.sunex.pl",
  SUW: "http://www.suwary.com.pl",
  SWD: "http://www.swissmed.pl",
  SGN: "http://www.sygnity.pl",
  SNT: "http://www.synektik.com.pl",
  TBL: "http://www.t-bull.com",
  TLX: "http://www.talex.pl",
  TAR: "http://www.tarczynski.pl",
  TMR: "http://www.tmr.sk",
  TPE: "http://www.tauron.pl",
  TEN: "http://www.tensquaregames.com",
  TRR: "http://www.termo-rex.pl",
  TSG: "http://www.tesgas.pl",
  TIM: "http://www.timsa.pl",
  TOR: "http://www.torpol.pl",
  TOW: "http://www.towerinvestments.pl",
  TOA: "http://www.toya.pl",
  TRK: "http://www.grupatrakcja.com",
  TRN: "http://www.transpolonia.pl",
  TRI: "http://www.tritondevelopment.pl",
  TXM: "http://www.txm.pl",
  ULM: "http://www.ulmaconstruction.pl",
  ULG: "http://www.ultimate-games.com",
  UNI: "http://www.unibep.pl",
  UCG: "http://www.unicreditgroup.eu",
  U2K: "http://www.unima2000.pl",
  UNT: "http://www.unimot.pl",
  URS: "http://www.ursus.com.pl",
  VTI: "http://www.ventureinc.com",
  VRC: "http://www.vercom.pl",
  VGO: "http://www.vigo.com.pl",
  VTL: "http://www.vistal.pl",
  VVD: "http://www.vividgames.com",
  VOT: "http://www.votum-sa.pl",
  VOX: "http://www.voxel.pl",
  VRG: "http://www.vistulagroup.pl",
  WXF: "http://www.warimpex.com",
  WAS: "http://www.wasko.pl",
  WWL: "http://www.wawel.com.pl",
  WLT: "http://www.wielton.com.pl",
  WIK: "http://www.wikana.pl",
  WPL: "http://www.wp.pl",
  WTN: "http://www.wittchen.com",
  WOJ: "http://www.wojas.com.pl",
  WSE: "http://www.workservice.pl",
  XTB: "http://www.xtb.com",
  XTP: "http://www.xtpl.com",
  YOL: "http://www.yologroup.pl",
  ZRE: "http://www.zremb-ch.com.pl",
  RPC: "http://www.ropczyce.com.pl",
  OTM: "http://www.zpcotmuchow.com.pl",
  ELZ: "http://www.elzab.com.pl",
  ZUK: "http://www.zuk.com.pl",
  ZMT: "http://www.zametsa.com",
  KGN: "http://www.kogeneracja.com.pl",
  ZEP: "http://www.zepak.com.pl",
  PUE: "http://www.zpue.pl",
  ZUE: "http://www.grupazue.pl",
};

export const noLogoUrls = [
  "http://www.interrao.lt",
  "http://www.4funmedia.pl",
  "http://www.ab.pl",
  "http://www.ac.com.pl",
  "http://www.action.pl",
  "http://www.agroton.com.ua",
  "http://www.allegro.eu",
  "http://www.altasa.pl",
  "http://altustfi.pl",
  "http://www.ampli.com.pl",
  "http://www.aplisens.pl",
  "http://www.apsenergia.pl",
  "http://www.arteria.pl",
  "http://www.astartakiev.com",
  "http://www.asseco-see.com",
  "http://www.atlantis-sa.pl",
  "http://www.atlasestates.com",
  "http://www.atrem.pl",
  "http://www.balticbridge.com.pl",
  "http://www.bbidevelopment.pl",
  "http://www.berling.pl",
  "http://www.benefitsystems.pl",
  "http://www.bik.com.pl",
  "http://www.bioton.pl",
  "http://www.bnpparibas.pl",
  "http://www.braster.eu",
  "http://www.ba-holding.pl",
  "http://www.capitalpark.pl",
  "http://www.cfiholding.pl",
  "http://www.cntsa.pl",
  "http://www.cityservice.eu",
  "http://www.ccenergy.pl",
  "http://www.coalenergy.com.ua",
  "http://www.cpdsa.pl",
  "http://www.comp.com.pl",
  "http://www.czerwonatorebka.pl",
  "http://www.grupapolsat.pl",
  "http://www.delko.com.pl",
  "http://www.echo.com.pl",
  "http://www.drozapol.pl",
  "http://www.edinvest.pl",
  "http://www.ekoexport.pl",
  "http://www.elektrotim.pl",
  "http://www.elkop.pl",
  "http://",
  "http://",
  "http://www.enap.com.pl",
  "http://www.energoinstal.pl",
  "http://www.erg.com.pl",
  "http://www.enterair.pl",
  "http://www.erbud.pl",
  "http://www.esotiqhenderson.com",
  "http://www.sniezka-sa.pl",
  "http://www.eurohold.bg",
  "http://www.forte.com.pl/pl",
  "http://www.fasing.pl",
  "http://www.famur.com",
  "http://www.feerum.pl",
  "http://www.fastfinance.pl",
  "http://www.ferro.pl",
  "http://www.fon-sa.pl",
  "http://www.ferrum.com.pl",
  "http://www.debica.com.pl",
  "http://www.getbacksa.pl",
  "http://www.gpm-vindexus.pl",
  "http://www.globalcosmed.pl",
  "http://www.groclin.com.pl",
  "http://www.grupakety.com",
  "http://www.helio.pl",
  "http://www.harperhygienics.pl",
  "http://www.herkules-polska.pl",
  "http://www.grupainwest.pl",
  "http://www.hollywoodsa.eu",
  "http://www.power.com.pl",
  "http://www.ibsmsa.pl",
  "http://www.imcagro.com.ua",
  "http://www.incsa.pl",
  "http://www.indygotechminerals.com",
  "http://www.inpro.com.pl",
  "http://www.interbud.com.pl",
  "http://https://intermatrade.pl",
  "http://www.ifcapital.pl",
  "http://www.intersport.pl",
  "http://www.introlsa.pl",
  "http://www.ifsa.pl",
  "http://www.invista.com.pl",
  "http://www.ipopema.pl",
  "http://www.izoblok.pl",
  "http://www.izolacja-jarocin.pl",
  "http://www.izostal.com.pl",
  "http://www.jwconstruction.com.pl",
  "http://www.jwwinvest.pl",
  "http://www.kernel.ua",
  "http://www.kdmshipping.com",
  "http://www.kbdom.eu",
  "http://www.efektsa.pl",
  "http://www.krakchemia.pl",
  "http://www.krynicki.pl",
  "http://www.ksgagro.com",
  "http://www.larq.pl",
  "http://www.libet.pl",
  "http://www.mitsa.pl",
  "http://www.lw.com.pl",
  "http://www.mabion.eu",
  "http://www.magnapolonia.com.pl",
  "http://www.mangata.com.pl",
  "http://www.makarony.pl",
  "http://www.maxcom.pl",
  "http://www.masterpharm.pl",
  "http://www.mdienergia.pl",
  "http://www.mercor.com.pl",
  "http://www.milkiland.nl",
  "http://www.mobruk.pl",
  "http://www.moj.com.pl",
  "http://www.mostostal-plock.pl",
  "http://www.mostostal.waw.pl",
  "http://www.mwtrade.pl",
  "http://www.nanogroup.eu",
  "http://http://rpsa.com.pl",
  "http://www.ntt.com.pl",
  "http://www.octava.com.pl",
  "http://www.orzel-bialy.com.pl",
  "http://www.patentus.pl",
  "http://www.pa-nova.com.pl",
  "http://www.pbg-sa.pl",
  "http://www.pcguard.pl",
  "http://www.zastal.pl",
  "http://www.pharmena.com.pl",
  "http://www.pjpmakrum.com",
  "http://www.polenergia.pl",
  "http://www.polimex-mostostal.pl",
  "http://www.pbkm.pl",
  "http://www.pozbud.pl",
  "http://www.pekabex.pl",
  "http://www.primamoda.com.pl",
  "http://www.prochem.com.pl",
  "http://www.pepees.pl",
  "http://www.kompap.pl",
  "http://www.r22.pl",
  "http://www.cormay.pl",
  "http://www.quercustfi.pl",
  "http://www.rankprogress.pl",
  "http://www.redan.com.pl",
  "http://www.regnon.com",
  "http://http://r.pl/",
  "http://www.relpol.com.pl",
  "http://www.resbud.pl",
  "http://www.reinhold-europe.se/en/home",
  "http://www.sanokrubber.pl",
  "http://www.holding.sanwil.com",
  "http://http://satisgroupsa.pl/",
  "http://www.secowarwick.com.pl",
  "http://www.rybki-seko.pl",
  "https://www.gpw.pl/pub/GPW/files/PDF/REG_S/Zalacznik_14_czesc_A.docx",
  "http://www.sadovayagroup.com",
  "http://www.simple.com.pl",
  "http://www.skotansa.pl",
  "http://www.skarbiecholding.pl",
  "http://www.skyline.com.pl",
  "http://www.sleepz.com",
  "http://www.solar.com.pl",
  "http://www.sonel.pl",
  "http://www.stalexport-autostrady.pl",
  "http://www.starhedge.pl",
  "http://www.suwary.com.pl",
  "http://www.swissmed.pl",
  "http://www.synektik.com.pl",
  "http://www.t-bull.com",
  "http://www.termo-rex.pl",
  "http://www.tesgas.pl",
  "http://www.torpol.pl",
  "http://www.grupatrakcja.com",
  "http://www.towerinvestments.pl",
  "http://www.toya.pl",
  "http://www.tritondevelopment.pl",
  "http://www.txm.pl",
  "http://www.unibep.pl",
  "http://www.ventureinc.com",
  "http://www.vistal.pl",
  "http://www.votum-sa.pl",
  "http://www.voxel.pl",
  "http://www.wikana.pl",
  "http://www.wojas.com.pl",
  "http://www.yologroup.pl",
  "http://www.zremb-ch.com.pl",
  "http://www.ropczyce.com.pl",
  "http://www.elzab.com.pl",
  "http://www.zuk.com.pl",
  "http://www.zepak.com.pl",
  "http://www.grupazue.pl",
];
