import { call, put, select, take, takeEvery } from "redux-saga/effects";
import { filter, get } from "lodash";
import genericSagaHandler from "./CommonSaga";
import {
  CHAT_WEBSOCKET_OPENED,
  INIT_CHAT_WEBSOCKET,
  POST_CHAT_MESSAGE_PROCESS,
  POST_CHAT_MESSAGE_PROCESS_SUCCESS,
  POST_CHAT_MESSAGE_START,
  RESET_CHAT_MESSAGES_START,
  WEBSOCKET_RECEIVED_CHAT_MESSAGE_PROCESS,
  WEBSOCKET_RECEIVED_CHAT_MESSAGE_PROCESS_SUCCESS,
  WEBSOCKET_RECEIVED_CHAT_MESSAGE_START,
} from "../actions/ChatActions";
import { eventChannel } from "@redux-saga/core";
import { getChatMessages, sendChatMessage } from "../services/ChatServices";
import HttpService from "../services/HttpService";
import Config from "../config/Config";
import dayjs from "dayjs";

let channel, socketInterval;

function* doChatChannel() {
  return eventChannel((emitter) => {
    HttpService.post(
      "https://api.stockup.cloud/chatHub/negotiate?negotiateVersion=1"
    ).then((resp) => {
      console.log(resp);
      const ws = new WebSocket(
        `${Config.BASE_WSS_URL}/chatHub?id=${resp.data.connectionToken}`
      );

      ws.onopen = () => {
        // connection opened
        // ws.send('something'); // send a message
        console.log("WebSockets", "openened");
        ws.send('{"protocol":"json","version":1}');
        return emitter({ type: CHAT_WEBSOCKET_OPENED });
      };

      ws.onerror = (e) => {
        // an error occurred
        console.log("WebSockets", e.message);
      };

      socketInterval = setInterval(() => {
        if (ws.readyState === ws.CONNECTING) {
          console.log("Websockets send ping");
          ws.send("{type: 6}");
        }
      }, 15000);

      ws.onmessage = (e) => {
        // just don't ask about the slice, it is the messed up response
        const message = JSON.parse(e.data.slice(0, -1));
        console.log("onReceive", message);
        if (message.target === "ReceiveChatMessage") {
          console.log("ReceiveChatMessageLukasz", message);
          return emitter({
            type: WEBSOCKET_RECEIVED_CHAT_MESSAGE_START,
            message,
          });
        }
      };
    });

    return () => {
      console.log("unsub");
    };
  });
}

function* doStartChatWebsocket() {
  if (channel && socketInterval) {
    clearInterval(socketInterval);
    channel.close();
  }

  channel = yield call(doChatChannel);
  while (true) {
    const action = yield take(channel);
    console.log("WebSockets", action);
    if (action.type === WEBSOCKET_RECEIVED_CHAT_MESSAGE_START) {
      console.log(action);
      // This is the delete event
      if (action.message.arguments[0] === null) {
        yield put({
          type: RESET_CHAT_MESSAGES_START,
        });
        yield call(doGetMesssage);
      } else {
        yield put({
          type: WEBSOCKET_RECEIVED_CHAT_MESSAGE_PROCESS_SUCCESS,
          payload: action.message.arguments,
        });
      }
    } else if (action.type === CHAT_WEBSOCKET_OPENED) {
      console.log("CHAT_WEBSOCKET_OPENED");
      yield call(doGetMesssage);
    }
  }
}

function* doGetMesssage() {
  yield genericSagaHandler(
    WEBSOCKET_RECEIVED_CHAT_MESSAGE_PROCESS,
    function* () {
      const { data } = yield getChatMessages({ page: 1, pageSize: 50 });
      console.log("doGetMessage", data);
      yield put({
        type: WEBSOCKET_RECEIVED_CHAT_MESSAGE_PROCESS_SUCCESS,
        payload: data.items.reverse(),
      });
    }
  );
}

function* doPostMessage({ payload }) {
  yield genericSagaHandler(POST_CHAT_MESSAGE_PROCESS, function* () {
    console.log("doPostMessage", payload);
    yield sendChatMessage({ message: payload });
    const sender = yield select(({ LoginReducer }) => LoginReducer.nick);
    yield put({
      type: POST_CHAT_MESSAGE_PROCESS_SUCCESS,
      payload: [
        {
          message: payload,
          sender,
          createdDate: dayjs().format(),
        },
      ],
    });
  });
}

export default function* UserReportSettingsSagas() {
  yield takeEvery(INIT_CHAT_WEBSOCKET, doStartChatWebsocket);
  yield takeEvery(WEBSOCKET_RECEIVED_CHAT_MESSAGE_START, doGetMesssage);
  yield takeEvery(POST_CHAT_MESSAGE_START, doPostMessage);
}
