import { clone, find, get } from "lodash";
import {
  GET_ANALYZE_REPORTS_PROCESS_SUCCESS,
  GET_COMPANY_REPORTS_PROCESS_SUCCESS,
  GET_REPORT_BY_ID_RESET,
  REPORTS_RESET,
  RESET_COMPANY_REPORTS_PAGE_START,
  SET_ANALYZE_REPORTS_PAGE_START,
  SET_COMPANY_REPORTS_PAGE_START,
  RESET_ANALYZE_REPORTS_START,
  SET_COMPANY_REPORTS_PREVIOUS_PAGE_START,
} from "../actions/ReportsActions";
const DEFAULT_STATE = { page: 1, items: {}, hasNextPage: false };
const ReportsReducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case REPORTS_RESET:
      return DEFAULT_STATE;
    case GET_COMPANY_REPORTS_PROCESS_SUCCESS:
      const { items, symbol, hasNextPage } = action.payload;

      const newStateItems = clone(state.items[symbol]) || [];

      items?.map((item) => {
        if (
          !find(newStateItems, (existingItem) => existingItem.id === item.id)
        ) {
          newStateItems.push(item);
        }
      });
      return { ...state, items: { [symbol]: items }, hasNextPage };

    case RESET_COMPANY_REPORTS_PAGE_START:
      return {
        ...state,
        page: 1,
      };
    case SET_COMPANY_REPORTS_PREVIOUS_PAGE_START:
      return {
        ...state,
        page: state.page - 1,
      };
    case SET_COMPANY_REPORTS_PAGE_START:
      return {
        ...state,
        page: state.page + 1,
      };
    // case REPORTS_GET_PROCESS_SUCCESS:
    //   const oldItems = get(state, ['reports', 'items'], []);
    //   const items = get(action.payload, ['items'], []);
    //   const newItems = oldItems.concat(items);
    //   const reports = {
    //     items: newItems,
    //   };
    //   return {
    //     ...state,
    //     reports,
    //   };
    default:
      return {
        ...state,
      };
  }
};

export default ReportsReducer;
