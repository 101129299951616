import dayjs from "dayjs";
import React from "react";
import { MAIN_DARK_COLOR, MAIN_LIGHT_COLOR } from "../../config/colors";

const AuthorChatBubble = ({ message, sender, date }) => {
  return (
    <div style={{ marginTop: 20, marginLeft: 50 }}>
      <div
        style={{
          display: "flex",
          flex: 1,
          backgroundColor: MAIN_DARK_COLOR,
          padding: 10,
          borderRadius: 20,
        }}
      >
        <span style={{ color: MAIN_LIGHT_COLOR }}>{message}</span>
      </div>
      <p
        style={{
          fontFamily: "Lato",
          color: MAIN_DARK_COLOR,
          fontSize: 12,
          marginBottom: 5,
          marginLeft: 5,
          marginTop: 5,
        }}
      >
        {sender} - {dayjs(date).format("DD.MM.YY - HH:mm")}
      </p>
    </div>
  );
};

export default AuthorChatBubble;
