import { find } from "lodash";
import React from "react";
import { MAIN_DARK_COLOR } from "../config/colors";

const Tabs = ({ tabs, onPress }) => {
  const selectedTab = find(tabs, (t) => t.selected);
  return (
    <>
      <div style={{ display: "flex", flex: 1 }}>
        {tabs &&
          tabs.length > 1 &&
          tabs.map((t) => {
            console.log(t.selected);
            return (
              <button
                key={`key_${t.id}`}
                onClick={() => onPress(t.id)}
                style={{
                  paddingLeft: 10,
                  paddingRight: 10,
                  background: "none",
                  borderWidth: 0,
                  borderBottomWidth: t.selected ? 2 : 0,
                  borderBottomColor: MAIN_DARK_COLOR,
                  display: "flex",
                  flex: 1,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {t.name}
              </button>
            );
          })}
      </div>
      <div>{selectedTab.component}</div>
    </>
  );
};

export default Tabs;
