import { call, put, select, takeEvery } from "@redux-saga/core/effects";
import { filter, get } from "lodash";
import {
  GET_ALERTS_PROCESS,
  GET_ALERTS_PROCESS_SUCCESS,
  GET_ALERTS_RESET,
  GET_ALERTS_START,
  GET_CANDLE_ANALYZER_SETTINGS_PROCESS,
  GET_CANDLE_ANALYZER_SETTINGS_PROCESS_SUCCESS,
  GET_CANDLE_ANALYZER_SETTINGS_START,
  GET_COMPANY_ALERTS_PROCESS,
  GET_COMPANY_ALERTS_PROCESS_SUCCESS,
  GET_COMPANY_ALERTS_START,
  GET_FORMATION_ANALYZER_SETTINGS_PROCESS,
  GET_FORMATION_ANALYZER_SETTINGS_PROCESS_SUCCESS,
  GET_FORMATION_ANALYZER_SETTINGS_START,
  RESET_ALERTS_PAGE_START,
  SET_ALERTS_PAGE_START,
  SET_CANDLE_ANALYZER_SETTINGS_PROCESS,
  SET_CANDLE_ANALYZER_SETTINGS_PROCESS_SUCCESS,
  SET_CANDLE_ANALYZER_SETTINGS_START,
  SET_COMPANY_ALERTS_PAGE_START,
  SET_COMPANY_ALERTS_PREVIOUS_PAGE_START,
  SET_FORMATION_ANALYZER_SETTINGS_PROCESS,
  SET_FORMATION_ANALYZER_SETTINGS_PROCESS_SUCCESS,
  SET_FORMATION_ANALYZER_SETTINGS_START,
} from "../actions/AnalyzerAlertsActions";
import {
  getAlerts,
  getCandleAnalyzerSettings,
  getFormationAnalyzerSettings,
} from "../services/CandleAnalyzerService";
import genericSagaHandler from "./CommonSaga";
import update from "immutability-helper";
import { ALERT_MODES, analyzerTypeMapping } from "../config/constants";

function* doGetCandleAnalyzerSettings() {
  yield genericSagaHandler(GET_CANDLE_ANALYZER_SETTINGS_PROCESS, function* () {
    yield put({
      type: GET_ALERTS_RESET,
    });
    const response = yield getCandleAnalyzerSettings({});
    const data = get(response, ["data", "items"]);
    const candleTypesData = update(data, {
      0: {
        selected: {
          $set: true,
        },
      },
    });
    yield put({
      type: GET_CANDLE_ANALYZER_SETTINGS_PROCESS_SUCCESS,
      payload: candleTypesData,
    });
    yield call(doGetAlerts, { payload: { analyzerType: ALERT_MODES.CANDLES } });
  });
}

function* doGetFormationAnalyzerSettings({ payload }) {
  yield genericSagaHandler(
    GET_FORMATION_ANALYZER_SETTINGS_PROCESS,
    function* () {
      yield put({
        type: GET_ALERTS_RESET,
      });
      const { type } = payload;
      const response = yield getFormationAnalyzerSettings({ Type: type.id });
      const data = get(response, ["data", "items"]);
      const formationTypesData = update(data, {
        0: {
          selected: {
            $set: true,
          },
        },
      });
      yield put({
        type: GET_FORMATION_ANALYZER_SETTINGS_PROCESS_SUCCESS,
        payload: formationTypesData,
      });
      yield put({
        type: RESET_ALERTS_PAGE_START,
      });
      yield call(doGetAlerts, {
        payload: { analyzerType: ALERT_MODES.FORMATIONS },
      });
    }
  );
}

function* doSetFormationAnalyzer({ payload }) {
  yield genericSagaHandler(
    SET_FORMATION_ANALYZER_SETTINGS_PROCESS,
    function* () {
      yield put({
        type: GET_ALERTS_RESET,
      });
      const { id } = payload;
      const { types } = yield select(
        ({ AnalyzerAlertsReducer }) => AnalyzerAlertsReducer
      );
      console.log(types);
      const newSelectedIndex = types.findIndex((it) => it.id === id);
      const currentSelectedIndex = types.findIndex((it) => it.selected);
      const newTypes = update(types, {
        [currentSelectedIndex]: {
          selected: { $set: false },
        },
        [newSelectedIndex]: {
          selected: { $set: true },
        },
      });
      yield put({
        type: SET_FORMATION_ANALYZER_SETTINGS_PROCESS_SUCCESS,
        payload: newTypes,
      });

      console.log("doGetAlerts call");
      yield put({
        type: RESET_ALERTS_PAGE_START,
      });
      yield call(doGetAlerts, {
        payload: { analyzerType: ALERT_MODES.FORMATIONS },
      });
    }
  );
}

function* doSetCandleAnalyzer({ payload }) {
  yield genericSagaHandler(SET_CANDLE_ANALYZER_SETTINGS_PROCESS, function* () {
    yield put({
      type: GET_ALERTS_RESET,
    });
    const { id } = payload;
    const { types } = yield select(
      ({ AnalyzerAlertsReducer }) => AnalyzerAlertsReducer
    );
    console.log(types);
    const newSelectedIndex = types.findIndex((it) => it.id === id);
    const currentSelectedIndex = types.findIndex((it) => it.selected);
    const newTypes = update(types, {
      [currentSelectedIndex]: {
        selected: { $set: false },
      },
      [newSelectedIndex]: {
        selected: { $set: true },
      },
    });
    yield put({
      type: SET_CANDLE_ANALYZER_SETTINGS_PROCESS_SUCCESS,
      payload: newTypes,
    });
    yield put({
      type: RESET_ALERTS_PAGE_START,
    });
    yield call(doGetAlerts, { payload: { analyzerType: ALERT_MODES.CANDLES } });
  });
}

function* doGetAlerts({ payload }) {
  console.log("getAlerts", payload);
  yield genericSagaHandler(GET_ALERTS_PROCESS, function* () {
    const { analyzerType, expectedTrendDirection } = payload;
    const types = yield select(({ AnalyzerAlertsReducer }) => {
      console.log("doGetAlerts", AnalyzerAlertsReducer);
      return AnalyzerAlertsReducer.types;
    });
    const currentSelectedIndex = types.findIndex((it) => it.selected);
    const analyzerName = get(types, [currentSelectedIndex, "name"]);
    const page = yield select(
      ({ AnalyzerAlertsReducer }) => AnalyzerAlertsReducer.page
    );
    const response = yield getAlerts({
      Page: page,
      analyzerType: analyzerTypeMapping[analyzerType],
      analyzerName,
      expectedTrendDirection,
    });

    const items = get(response, ["data", "items"]);
    const hasNextPage =
      filter(
        get(response, ["data", "links"]),
        (item) => item.rel === "nextPage"
      ).length > 0;
    yield put({
      type: GET_ALERTS_PROCESS_SUCCESS,
      payload: { items, hasNextPage, page },
    });
  });
}

function* doGetCompanyAlerts({ payload }) {
  yield genericSagaHandler(GET_COMPANY_ALERTS_PROCESS, function* () {
    const symbol = payload;
    const page = yield select(
      ({ CompanyAlertsReducer }) => CompanyAlertsReducer.page
    );
    const response = yield getAlerts({ Page: page, symbol });
    const items = get(response, ["data", "items"]);
    const hasNextPage =
      filter(
        get(response, ["data", "links"]),
        (item) => item.rel === "nextPage"
      ).length > 0;
    console.log("doGetCompanyAlerts", items);

    if (items && items.length > 0) {
      yield put({
        type: GET_COMPANY_ALERTS_PROCESS_SUCCESS,
        payload: {
          symbol,
          items,
          hasNextPage,
        },
      });
    }
  });
}

export default function* AnalyzerAlertsSagas() {
  yield takeEvery(
    GET_CANDLE_ANALYZER_SETTINGS_START,
    doGetCandleAnalyzerSettings
  );
  yield takeEvery(
    GET_FORMATION_ANALYZER_SETTINGS_START,
    doGetFormationAnalyzerSettings
  );
  yield takeEvery(SET_CANDLE_ANALYZER_SETTINGS_START, doSetCandleAnalyzer);
  yield takeEvery(GET_ALERTS_START, doGetAlerts);
  yield takeEvery(SET_ALERTS_PAGE_START, doGetAlerts);
  yield takeEvery(
    SET_FORMATION_ANALYZER_SETTINGS_START,
    doSetFormationAnalyzer
  );

  yield takeEvery(GET_COMPANY_ALERTS_START, doGetCompanyAlerts);
  yield takeEvery(SET_COMPANY_ALERTS_PAGE_START, doGetCompanyAlerts);
  yield takeEvery(SET_COMPANY_ALERTS_PREVIOUS_PAGE_START, doGetCompanyAlerts);
}
