import ConfigReducer from "./ConfigReducer";
import ArticlesReducer from "./ArticlesReducer";
import ErrorReducer from "./ErrorReducer";
import ProcessReducer from "./ProcessReducer";
import AllReportsReducer from "./AllReportsReducer";
import ReportDetailsReducer from "./ReportDetailsReducer";
import ReportDownloadsReducer from "./ReportDownloadsReducer";
import LoginReducer from "./LoginReducer";
import ArticleCommentsReducer from "./ArticleCommentsReducer";
import CompaniesTabReducer from "./CompaniesTabReducer";
import CompaniesReducer from "./CompaniesReducer";
import ChartReducer from "./ChartReducer";
import ReportsReducer from "./ReportsReducer";
import CompanyAlertsReducer from "./CompanyAlertsReducer";
import ChatReducer from "./ChatReducer";

export default {
  ConfigReducer,
  ArticlesReducer,
  ErrorReducer,
  ProcessReducer,
  AllReportsReducer,
  ReportDetailsReducer,
  ReportDownloadsReducer,
  LoginReducer,
  ArticleCommentsReducer,
  CompaniesTabReducer,
  CompaniesReducer,
  ChartReducer,
  ReportsReducer,
  CompanyAlertsReducer,
  ChatReducer,
};
