import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Provider } from "react-redux";
import createSagaMiddleware from "redux-saga";
import { applyMiddleware, combineReducers, createStore } from "redux";
import reducers from "./reducers";
import HomeRoute from "./routes/HomeRoute";
import LoginRoute from "./routes/LoginRoute";
import { composeWithDevTools } from "redux-devtools-extension";
import sagas from "./sagas";

import "./App.css";
import ArticleRoute from "./routes/ArticleRoute";
import AboutRoute from "./routes/AboutRoute";
import TermsRoute from "./routes/TermsRoute";
import EspiRoute from "./routes/EspiRoute";
import EspiDetailsRoute from "./routes/EspiDetailsRoute";
import CompaniesRoute from "./routes/CompaniesRoute";
import CompanyDetailsRoute from "./routes/CompanyDetailsRoute";
import MainWrapper from "./components/MainWrapper";
const sagaMiddleware = createSagaMiddleware();
const appReducer = combineReducers(reducers);

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

const store = createStore(
  rootReducer,
  {},
  composeWithDevTools(applyMiddleware(sagaMiddleware))
);

sagaMiddleware.run(sagas);

export default function App() {
  return (
    <Provider store={store}>
      <MainWrapper>
        <Router>
          <Switch>
            <Route path="/login">
              <LoginRoute />
            </Route>
            <Route path={"/companies/:symbol"}>
              <CompanyDetailsRoute />
            </Route>
            <Route path="/companies">
              <CompaniesRoute />
            </Route>
            <Route path="/espi/:id">
              <EspiDetailsRoute />
            </Route>
            <Route path="/espi">
              <EspiRoute />
            </Route>
            <Route path="/about">
              <AboutRoute />
            </Route>
            <Route path="/terms">
              <TermsRoute />
            </Route>
            <Route path="/article/:id">
              <ArticleRoute />
            </Route>
            <Route path="/login">
              <LoginRoute />
            </Route>
            <Route path="/">
              <HomeRoute />
            </Route>
          </Switch>
        </Router>
      </MainWrapper>
    </Provider>
  );
}
