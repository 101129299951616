import React, { useEffect } from "react";
import ReactMarkdown from "react-markdown";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { GET_REPORT_BY_ID_START } from "../actions/ReportsActions";
import BasePage from "../components/BasePage";
import ReportDownloads from "../components/ReportDownloads";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import {
  MAIN_DARK_COLOR
} from "../config/colors";
import {
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
} from "react-share";
import MobileConfig from "../config/Config";

const EspiDetailsRoute = () => {
  const dispatch = useDispatch();

  const { id } = useParams();

  const report = useSelector(
    ({ ReportDetailsReducer }) => ReportDetailsReducer
  );

  useEffect(() => {
    dispatch({
      type: GET_REPORT_BY_ID_START,
      payload: {
        reportId: id,
      },
    });
  }, []);

  return (
    <BasePage>
      <h1><Link to={`/companies/${report?.stockSymbol?.abbreviation}`}
          style={{ 
            color: MAIN_DARK_COLOR, 
            textDecoration: 'none'  
            }}>{report?.companyName?.name}</Link>
      </h1>
      <h2>
        {report?.dateTime} - {report?.title?.content}
      </h2>
      <div style={{ display: "flex", flex: 1, justifyContent: "flex-end" }}>
        <EmailShareButton
          url={`${MobileConfig.BASE_PORTAL_URL}espi/${encodeURIComponent(id)}`}
        >
          <EmailIcon size={32} round style={{ marginRight: 5 }} />
        </EmailShareButton>
        <FacebookShareButton
          url={`${MobileConfig.BASE_PORTAL_URL}espi/${encodeURIComponent(id)}`}
        >
          <FacebookIcon size={32} round={true} style={{ marginRight: 5 }} />
        </FacebookShareButton>
        <TwitterShareButton
          url={`${MobileConfig.BASE_PORTAL_URL}espi/${encodeURIComponent(id)}`}
        >
          <TwitterIcon size={32} round={true} style={{ marginRight: 5 }} />
        </TwitterShareButton>
        <LinkedinShareButton
          url={`${MobileConfig.BASE_PORTAL_URL}espi/${encodeURIComponent(id)}`}
        >
          <LinkedinIcon size={32} round={true} style={{ marginRight: 5 }} />
        </LinkedinShareButton>
      </div>
      <ReactMarkdown>{report?.content?.content}</ReactMarkdown>
      <ReportDownloads reportId={id} />
    </BasePage>
  );
};

export default EspiDetailsRoute;
