import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GET_COMPANIES_START } from '../actions/CompanyActions';

const useCompanyName = (symbol) => {
  const dispatch = useDispatch();
  const companies = useSelector(
    ({ CompaniesReducer }) => CompaniesReducer.items
  );

  useEffect(() => {
    if (!companies || companies.length === 0) {
      dispatch({
        type: GET_COMPANIES_START,
      });
    }
  }, []);

  return companies.filter((it) => it.symbol.abbreviation === symbol)[0];
};

export default useCompanyName;
