import React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GET_CANDLES_RESET, GET_CANDLES_START } from "../actions/ChartActions";
import Chart from "react-apexcharts";
import dayjs from "dayjs";
import { i18n } from "../config/i18n";
import { useRef } from "react";
import useDimensions from "../hooks/useDimensionsHook";
import { find, get } from "lodash";
import { GREEN_COLOR, MAIN_DISABLED_COLOR, RED_COLOR } from "../config/colors";
import usePrevious from "../hooks/usePrevious";

const prepareCandleData = (candles) => {
  const newCandles = [];
  candles?.forEach((candle, idx) => {
    let barColor = MAIN_DISABLED_COLOR;
    if (candles[idx + 1]?.openClose.close > candle?.openClose.close) {
      barColor = GREEN_COLOR;
    } else if (candles[idx + 1]?.openClose.close < candle?.openClose.close) {
      barColor = RED_COLOR;
    } else if (candles[idx + 1]?.openClose.close === candle?.openClose.close) {
      barColor = "#22cfff";
    }
    newCandles.push({
      x: dayjs(candle.identification.time.dateTime).format(
        i18n.CHART_DATE_FORMAT
      ),
      y: get(candle, "volume.amount", 0),
      fillColor: barColor,
    });
  });

  return [{ name: i18n.VOLUME, data: newCandles.reverse() }];
};

const VolumeBarChart = ({ symbol, rangeItems, height }) => {
  const dispatch = useDispatch();
  const candles = useSelector(({ ChartReducer }) => ChartReducer.items);
  const now = new Date();
  const [seriesState, setSeries] = useState();
  const container = useRef();
  const containerWidth = useDimensions(container).width;

  const previousRange = usePrevious(rangeItems);

  useEffect(() => {
    dispatch({
      type: GET_CANDLES_RESET,
    });
  }, [dispatch]);

  useEffect(() => {
    const selectedRange = find(rangeItems, (it) => it.selected);
    const previousSelectedRange = find(previousRange, (it) => it.selected);
    if (
      (!candles && selectedRange) ||
      (previousSelectedRange &&
        selectedRange.value !== previousSelectedRange.value)
    ) {
      dispatch({
        type: GET_CANDLES_START,
        payload: {
          symbol,
          size: selectedRange.value,
          dateTo: new Date(),
          dateFrom: new Date(now.setDate(now.getDate() - selectedRange.value)),
          candleType: "Day",
        },
      });
    }
  }, [dispatch, candles, rangeItems]);

  useEffect(() => {
    setSeries(prepareCandleData(candles));
  }, [candles]);

  const [options, setOptions] = useState({
    dataLabels: {
      enabled: false,
    },
    chart: {
      id: "basic-bar",
    },
  });

  return (
    <div
      ref={container}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <h2>{i18n.VOLUME}</h2>
      {seriesState && (
        <Chart
          options={options}
          series={seriesState}
          type="bar"
          width={containerWidth}
          height={height}
        />
      )}
    </div>
  );
};
export default VolumeBarChart;
