import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { LOGOUT_START } from "../actions/LoginActions";
import { i18n } from "../config/i18n";

const AuthWelcome = () => {
  const nick = useSelector(({ LoginReducer }) => LoginReducer.nick);
  const dispatch = useDispatch();

  if (!nick) {
    return null;
  }
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
      }}
    >
      <b>Cześć {nick}!</b>
      <button
        style={{
          border: "none",
          background: "none",
          textDecoration: "underline",
          fontSize: 12,
        }}
        onClick={() => dispatch({ type: LOGOUT_START })}
      >
        {i18n.LOGOUT}
      </button>
    </div>
  );
};

export default AuthWelcome;
