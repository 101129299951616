import { UI_MODES } from './constants';

export const DARK_BLUE = '#001F3B';
export const LIGHT_BLUE = '#0C3357';
export const LIGHTER_BLUE = '#1963A8';
export const LIGHT_GREY = '#4C5E6E';
export const LIGHTER_GREY = '#969696';
export const ACTIVE_GREEN = '#6FE33F';
export const ACTIVE_ORANGE = '#FE8124';
export const LIGHT_TEXT = '#FFF';
export const DARK_TEXT = '#000';
export const NEGATIVE = '#F00305';
export const POSITIVE = '#6FE33F';
export const NEUTRAL = '#FFFFFF';

export const MAIN_LIGHT_COLOR = '#FFFFFF';
export const MAIN_DARK_COLOR = '#0C3357';
export const MAIN_DISABLED_COLOR = '#F0F5F5';
export const MAIN_DARK_COLOR_LIGHTER = 'rgba(12, 51, 87, 0.4)';
export const MAIN_SHADOW_COLOR = 'rgba(24, 66, 106, 0.4)';

// export const MAIN_LIGHT_COLOR = '#051524';
// export const MAIN_DARK_COLOR = 'rgba(255, 255, 255, 0.9)';
// export const MAIN_DARK_COLOR_LIGHTER = 'rgba(255, 255, 255, 0.4)';
// export const MAIN_SHADOW_COLOR = 'rgba(255, 255, 255, 0.4)';

export const GRID_LINE_COLOR = '#e3e3e3';

export const RED_COLOR = 'rgba(255, 0, 0, 1)';
export const RED_COLOR_LIGHTER = 'rgba(255, 0, 0, 0.8)';

export const GREEN_COLOR = 'rgba(39, 200, 55, 1)';
export const GREEN_COLOR_LIGHTER = 'rgba(39, 200, 55, 0.8)';

export const YELLOW_COLOR = 'rgba(255, 199, 0, 1)';
export const YELLOW_COLOR_LIGHTER = 'rgba(255, 199, 0, 0.8)';

export const LINK_COLOR = '#64B5FF';

export const colorModes = {
  LIGHT: {
    MAIN_LIGHT_COLOR: '#FFFFFF',
    MAIN_DARK_COLOR: '#0C3357',
    MAIN_DARK_COLOR_LIGHTER: 'rgba(12, 51, 87, 0.4)',
    MAIN_SHADOW_COLOR: 'rgba(24, 66, 106, 0.4)',
    WHITE: '#FFFFFF',
    RED_COLOR: 'rgba(255, 0, 0, 1)',
    GREEN_COLOR: 'rgba(39, 200, 55, 1)',
    barStyle: 'dark-content',
    mode: UI_MODES.LIGHT,
  },
  DARK: {
    MAIN_LIGHT_COLOR: '#051524',
    MAIN_DARK_COLOR: 'rgba(255, 255, 255, 0.9)',
    MAIN_DARK_COLOR_LIGHTER: 'rgba(255, 255, 255, 0.4)',
    MAIN_SHADOW_COLOR: 'rgba(255, 255, 255, 0.4)',
    WHITE: '#FFFFFF',
    RED_COLOR: 'rgba(255, 0, 0, 1)',
    GREEN_COLOR: 'rgba(39, 200, 55, 1)',
    barStyle: 'light-content',

    mode: UI_MODES.DARK,
  },
};
