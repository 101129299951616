import { MARKET } from "../config/constants";
import HttpService from "./HttpService";

export const getCandleAnalyzerSettings = ({ Page = 1, PageSize = 50 }) =>
  HttpService.get(`/candle-analyzer-setting?Page=${Page}&PageSize=${PageSize}`);

export const getFormationAnalyzerSettings = ({
  Type,
  Page = 1,
  PageSize = 50,
}) =>
  HttpService.get(
    `/candle-formation-analyzer-setting?Page=${Page}&PageSize=${PageSize}&Type=${Type}`
  );

export const getAlerts = ({
  Page = 1,
  PageSize = 10,
  Market = MARKET,
  analyzerType,
  analyzerName,
  symbol,
  expectedTrendDirection,
}) => {
  let urlQuery = `/alerts?Page=${Page}&PageSize=${PageSize}&Market=${Market}`;
  if (analyzerName) {
    urlQuery += `&analyzerName=${analyzerName}`;
  }
  if (analyzerType) {
    urlQuery += `&analyzerType=${analyzerType}`;
  }
  if (symbol) {
    urlQuery += `&Symbol=${symbol}`;
  }
  if (expectedTrendDirection) {
    urlQuery += `&expectedTrendDirection=${expectedTrendDirection}`;
  }

  return HttpService.get(urlQuery);
};
