export const GET_ALL_ARTICLES_PROCESS = "GET_ALL_ARTICLES_PROCESS";
export const GET_ALL_ARTICLES_START = "GET_ALL_ARTICLES_START";
export const GET_ALL_ARTICLES_PROCESS_START = "GET_ALL_ARTICLES_PROCESS_START";
export const GET_ALL_ARTICLES_PROCESS_SUCCESS =
  "GET_ALL_ARTICLES_PROCESS_SUCCESS";
export const GET_ALL_ARTICLES_PROCESS_ERROR = "GET_ALL_ARTICLES_PROCESS_ERROR";

export const RESET_ALL_ARTICLES_PAGE_START = "RESET_ALL_ARTICLES_PAGE_START";
export const SET_ALL_ARTICLES_PAGE_START = "SET_ALL_ARTICLES_PAGE_START";

export const GET_LATEST_ARTICLES_PROCESS = "GET_LATEST_ARTICLES_PROCESS";
export const GET_LATEST_ARTICLES_START = "GET_LATEST_ARTICLES_START";
export const GET_LATEST_ARTICLES_PROCESS_START =
  "GET_LATEST_ARTICLES_PROCESS_START";
export const GET_LATEST_ARTICLES_PROCESS_SUCCESS =
  "GET_LATEST_ARTICLES_PROCESS_SUCCESS";
export const GET_LATEST_ARTICLES_PROCESS_ERROR =
  "GET_LATEST_ARTICLES_PROCESS_ERROR";

export const GET_ARTICLE_BY_ID_PROCESS = "GET_ARTICLE_BY_ID_PROCESS";
export const GET_ARTICLE_BY_ID_START = "GET_ARTICLE_BY_ID_START";
export const GET_ARTICLE_BY_ID_PROCESS_START =
  "GET_ARTICLE_BY_ID_PROCESS_START";
export const GET_ARTICLE_BY_ID_PROCESS_SUCCESS =
  "GET_ARTICLE_BY_ID_PROCESS_SUCCESS";
export const GET_ARTICLE_BY_ID_PROCESS_ERROR =
  "GET_ARTICLE_BY_ID_PROCESS_ERROR";
