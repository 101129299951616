import React from "react";
import { companiesWebsite, noLogoUrls } from "../config/companiesWebsite";

const CompanyLogo = ({ symbol }) => {
  if (
    !companiesWebsite[symbol] ||
    noLogoUrls.indexOf(companiesWebsite[symbol]) !== -1
  ) {
    return null;
  }

  return (
    <img
      src={`//logo.clearbit.com/${companiesWebsite[symbol]}`}
      style={{ objectFit: "contain" }}
    />
  );
};

export default CompanyLogo;
